import { Box } from 'reflexbox';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import { useState } from 'react';
import { Button, Control } from '../common';
import { cumulablePointsMap, pointsMap } from './Win';

const fixedScoresPoints: pointsMap = {
  STRAIGHT_NO_FLOWER: {
    points: 4,
    isRare: false,
    displayName: 'Straight (no flower)',
  },
  STRAIGHT: { points: 1, isRare: false, displayName: 'Straight' },
  PONG_PONG: { points: 2, isRare: false, displayName: 'Pong Pong' },
  HALF_COLOR: { points: 2, isRare: false, displayName: 'Half color' },
  FULL_COLOR: { points: 4, isRare: false, displayName: 'Full color' },
  SEVEN_PAIRS: { points: 3, isRare: false, displayName: '7 pairs' },
  CONCEALED_HAND: { points: 1, isRare: false, displayName: 'Concealed hand' },
  PONG_OWN_WIND: { points: 1, isRare: false, displayName: 'Pong own wind' },
  PONG_ROUND_WIND: { points: 1, isRare: false, displayName: 'Pong round wind' },
  FLOWER_UP: { points: 1, isRare: true, displayName: 'Flower up' },
  HAIDILAO: {
    points: 1,
    isRare: true,
    displayName: 'Haidilao',
  },
  ROBBING_KANG: {
    points: 1,
    isRare: true,
    displayName: 'Robbing kang',
  },
  THREE_LESSER_SCHOLARS: {
    points: 3,
    isRare: true,
    displayName: '3 lesser scholars',
  },
  FOUR_LESSER_BLESSINGS: {
    points: 4,
    isRare: true,
    displayName: '4 lesser blessings',
  },
  MIXED_TERMINALS: {
    points: 4,
    isRare: true,
    displayName: 'Mixed terminals',
  },
  THREE_GREAT_SCHOLARS: {
    points: 5,
    isRare: true,
    displayName: '3 great scholars',
  },
  FOUR_GREAT_BLESSINGS: {
    points: 5,
    isRare: true,
    displayName: '4 great blessings',
  },
  EIGHT_IMMORTALS: {
    points: 5,
    isRare: true,
    displayName: '8 flowers',
  },
  HIDDEN_TREASURE: {
    points: 5,
    isRare: true,
    displayName: 'Hidden treasure',
  },
  PURE_TERMINALS: {
    points: 5,
    isRare: true,
    displayName: 'Pure terminals',
  },
  ALL_HONORS: {
    points: 5,
    isRare: true,
    displayName: 'All honors',
  },
  FOUR_KANGS: {
    points: 5,
    isRare: true,
    displayName: '4 kangs',
  },
  NINE_GATES: {
    points: 5,
    isRare: true,
    displayName: '9 gates',
  },
  HEAVENLY_HAND: {
    points: 10,
    isRare: true,
    displayName: 'Heavenly hand',
  },
  EARTHLY_HAND: {
    points: 10,
    isRare: true,
    displayName: 'Earthly hand',
  },
  THIRTEEN_WONDERS: { points: 10, isRare: true, displayName: '13 wonders' },
};

const cumulableScoresPoints: cumulablePointsMap = {
  ANIMAL: {
    points: 1,
    isRare: false,
    apply: (num: number) => {
      const fullSetExtraPoint = num === 4 ? 1 : 0;
      return num + fullSetExtraPoint;
    },
    displayName: 'Animal',
  },
  FLOWER: {
    points: 1,
    isRare: false,
    apply: (num) => {
      const fullSetExtraPoint = num === 4 ? 1 : 0;
      return num + fullSetExtraPoint;
    },
    displayName: 'Flower',
  },
  POWER_TILE: {
    points: 1,
    isRare: false,
    apply: (num) => num,
    displayName: 'Power tile',
  },
};

export type WinningCombination = {
  fixedScores: string[];
  cumulableScores: {
    [score: string]: number;
  };
};

const Combination = ({
  onSubmit,
}: {
  onSubmit: (points: number, winningCombination: WinningCombination) => void;
}) => {
  const [winningCombination, setWinningCombination] =
    useState<WinningCombination>({
      fixedScores: [],
      cumulableScores: {
        ANIMAL: 0,
        FLOWER: 0,
        POWER_TILE: 0,
      },
    });
  const [fixedScores, setFixedScores] = useState<{ [score: string]: boolean }>(
    {}
  );
  const [cumulableScores, setCumulableScores] = useState<{
    [score: string]: number;
  }>({
    ANIMAL: 0,
    FLOWER: 0,
    POWER_TILE: 0,
  });

  const updateFixedScore = (scoreName: string) => {
    const scores = { ...fixedScores };
    scores[scoreName] = scores[scoreName] ? false : true;
    setFixedScores(scores);
    setWinningCombination({
      ...winningCombination,
      fixedScores: Object.entries(scores)
        .filter(([v]) => v)
        .map(([k]) => k), // Only keep the fixed scores selected by the user
    });
  };

  const fixedTotal = Object.entries(fixedScores).reduce(
    (sum: number, [name, checked]: [string, boolean]) =>
      sum + (checked ? fixedScoresPoints[name].points : 0),
    0
  );

  const increaseCumulableScore = (scoreName: string) => {
    const scores = { ...cumulableScores };
    scores[scoreName] += 1;
    if (scores[scoreName] > 4) {
      scores[scoreName] = 0;
    }
    setCumulableScores(scores);
    setWinningCombination({
      ...winningCombination,
      cumulableScores: scores,
    });
  };

  const cumulableTotal = Object.entries(cumulableScores).reduce(
    (sum: number, [name, num]: [string, number]) =>
      sum + cumulableScoresPoints[name].apply(num),
    0
  );

  const totalPoints = fixedTotal + cumulableTotal;

  return (
    <Box
      mx={2}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100vh"
    >
      <h1>Select all combinations from your winning hand</h1>
      <Box display="flex" flexWrap="wrap" my={2} mx={-2}>
        {Object.keys(fixedScoresPoints).map((key) => {
          if (fixedScoresPoints[key].isRare) {
            return null;
          }
          return (
            <Control
              key={key}
              onClick={() => updateFixedScore(key)}
              checked={fixedScores[key]}
              m={2}
              width={90}
            >
              {fixedScoresPoints[key].displayName}
            </Control>
          );
        })}
        <Box mx={2}>
          <Accordion>
            <AccordionSummary>Click for more options</AccordionSummary>
            <AccordionDetails>
              <Box display="flex" flexWrap="wrap">
                {Object.keys(fixedScoresPoints).map((key) => {
                  if (!fixedScoresPoints[key].isRare) {
                    return null;
                  }
                  return (
                    <Control
                      key={fixedScoresPoints[key].displayName}
                      onClick={() => updateFixedScore(key)}
                      checked={fixedScores[key]}
                      m={2}
                      width={90}
                    >
                      {fixedScoresPoints[key].displayName}
                    </Control>
                  );
                })}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
      <Box mx={-2} display="flex" flexWrap="wrap">
        {Object.keys(cumulableScoresPoints).map((key) => (
          <Button
            flexDirection="column"
            key={key}
            onClick={() => increaseCumulableScore(key)}
            m={2}
            width={90}
            fontSize={18}
          >
            {cumulableScoresPoints[key].displayName} <br />
            <Box color="#586EE1" fontWeight="bold">
              {cumulableScores[key]}
            </Box>
          </Button>
        ))}
      </Box>
      <Button
        size={12}
        flexGrow={0}
        onClick={() =>
          totalPoints > 0 ? onSubmit(totalPoints, winningCombination) : {}
        }
        mb={3}
      >
        {totalPoints} points - Continue
      </Button>
    </Box>
  );
};
export default Combination;
