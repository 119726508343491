import { useState } from 'react';
import { PaymentType, ActionWithSub, SubAction } from '../typings';
import { Button } from '../common';
import PayFromAllOtherPlayers from './Payments/PayFromAllOtherPlayers';
import PayFromSinglePlayer from './Payments/PayFromSinglePlayer';
import { Box } from 'reflexbox';

const SubActionPage = ({ action }: { action: ActionWithSub }) => {
  const [payFromOtherPlayers, setPayFromOtherPlayer] =
    useState<SubAction | null>(null);
  const [payFromSinglePlayer, setPayFromSinglePlayer] =
    useState<SubAction | null>(null);

  if (payFromOtherPlayers) {
    return (
      <PayFromAllOtherPlayers
        actionType={action.type}
        chips={action.chips}
        metadata={{ subtype: payFromOtherPlayers.type }}
      />
    );
  }

  if (payFromSinglePlayer) {
    return (
      <PayFromSinglePlayer
        actionType={action.type}
        chips={action.chips}
        metadata={{ subtype: payFromSinglePlayer.type }}
      />
    );
  }

  const renderPaymentScreen = (s: SubAction) => {
    if (PaymentType.FROM_ALL_PLAYERS === s.payment) {
      setPayFromOtherPlayer(s);
    } else if (PaymentType.FROM_SINGLE_PLAYER === s.payment) {
      setPayFromSinglePlayer(s);
    }
  };

  return (
    <Box
      width={1}
      display="flex"
      justifyContent="space-between"
      flexDirection="column"
      height="100vh"
      px={2}
    >
      <h1>Select one of the option:</h1>
      {action.subtypes.map((s) => (
        <Button my={4} width="100%" onClick={() => renderPaymentScreen(s)}>
          {s.name}
        </Button>
      ))}
    </Box>
  );
};

export default SubActionPage;
