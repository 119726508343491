import { Box, makeStyles } from '@material-ui/core';
import { useState } from 'react';
import AsyncScreenTransition from '../AsyncScreenTransition';
import { Button, Layout } from '../common';
import { executeFunction } from '../common/supabase';
import Reconcile from './Reconcile';
import { useGame } from '../GameContext';
import GameStats from '../GameStats';

const useStyles = makeStyles({
  reconcileLink: {
    color: 'white',
    textDecoration: 'underline',
  },
});

export default () => {
  const { endGame, game } = useGame();
  const classes = useStyles();
  const [hasConfirmed, setHasConfirmed] = useState(false);
  const [isReconcileOpen, setIsReconcileOpen] = useState(false);

  if (isReconcileOpen) {
    return <Reconcile onClose={() => setIsReconcileOpen(false)} />;
  }

  if (hasConfirmed) {
    return (
      <AsyncScreenTransition
        asyncCall={async () => {
          await executeFunction('updateEntityById', {
            payload: {
              ...game,
              is_game_over: true,
              ended_at: game.ended_at || new Date().toISOString(),
            },
            entityName: 'games',
          });

          endGame();
        }}
        onForceContinue={async () => endGame()}
      />
    );
  }

  return (
    <Layout
      body={
        <>
          <GameStats />
          <Box>
            <span
              className={classes.reconcileLink}
              onClick={() => setIsReconcileOpen(true)}
            >
              Reconcile data
            </span>
          </Box>
        </>
      }
      footer={
        <Button my={2} onClick={() => setHasConfirmed(true)}>
          Click to end the game
        </Button>
      }
    />
  );
};
