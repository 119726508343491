import { Box } from 'reflexbox';
import { Button } from './common';
import { useHistory } from 'react-router';
import { Emoji, EmojiType } from './emojis';
import { useAuth } from './AuthContext';

export default () => {
  const history = useHistory();
  const { isSignedIn, signIn } = useAuth();

  if (isSignedIn) {
    history.push('/menu');
  }

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      height="100vh"
      flexDirection="column"
      p={4}
    >
      <Box
        display="flex"
        justifyContent="space-evenly"
        alignItems="center"
        flexGrow={1}
        style={{ fontSize: 40 }}
      >
        <div
          style={{
            textShadow: `0 0 17px #96EF48, 0 0 70px rgba(150,239,72,0.5)`,
            fontWeight: 'bold',
          }}
        >
          Mahjongify
        </div>
        <Emoji type={EmojiType.MAHJONG} />
        <Emoji type={EmojiType.RABBIT} />
      </Box>

      {!isSignedIn && (
        <Button
          flexGrow={0}
          fullWidth
          onClick={() => {
            signIn();
            history.push('/menu');
          }}
        >
          Log in
        </Button>
      )}
    </Box>
  );
};
