import { Box, CircularProgress } from '@material-ui/core';
import { ReactElement } from 'react';

export const WithCircularLoader = ({
  isLoading,
  children,
}: {
  isLoading: boolean;
  children: ReactElement[] | ReactElement;
}) => {
  return isLoading ? (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <CircularProgress />
    </Box>
  ) : (
    <>{children}</>
  );
};
