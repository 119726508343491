import styled from 'styled-components';
import { Button } from './Button';

type ControlProps = {
  readonly checked: boolean;
  readonly m: number;
};

export const Control = styled(Button)<ControlProps>`
  background: ${({ checked }) =>
    checked ? 'rgba(150,239,72,0.2)' : 'transparent'};
  flex-grow: 1;
  font-size: 18px;
`;

// export const Control = (props: any) => {
//   const Btn = styled(Button)({
//     background: props.checked ? 'rgba(150,239,72,0.2)' : 'transparent',
//   });
//   const otherProps = {
//     width: 80,
//     flexGrow: 1,
//     m: 1,
//     fontSize: 15,
//   };
//   return (
//     <Btn {...otherProps} {...props}>
//       {props.label}
//     </Btn>
//   );
// };
