import { Box } from '@material-ui/core';

export const WithLoader = ({
  body,
  footer,
  isLoading,
}: {
  body: any;
  footer?: any;
  isLoading: boolean;
}) => {
  return (
    <Box
      display="flex"
      height="100vh"
      px={2}
      py={2}
      flexDirection="column"
      justifyContent="space-between"
    >
      {isLoading ? <h1>Loading...</h1> : <>{body}</>}
      {footer}
    </Box>
  );
};
