import { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import {
  PlayerAction,
  PaymentType,
  NoPaymentAction,
  ActionWithSub,
  PaymentAction,
  ActionType,
  ActionSubType,
} from '../typings';
import SubAction from './SubAction';
import { Button } from '../common';
import NoPayment from './Payments/NoPayment';
import PayToAllOtherPlayers from './Payments/PayToAllOtherPlayers';
import PayFromAllOtherPlayers from './Payments/PayFromAllOtherPlayers';
import PayFromSinglePlayer from './Payments/PayFromSinglePlayer';
import { Box } from 'reflexbox';
import { Emoji, EmojiType } from '../emojis';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';

export const actions: PlayerAction[] = [
  {
    type: ActionType.YAO,
    name: 'Yao',
    chips: 3,
    isRare: false,
    subtypes: [
      {
        name: 'Animal',
        type: ActionSubType.ANIMAL,
        payment: PaymentType.FROM_ALL_PLAYERS,
      },
      {
        name: 'Self flower',
        type: ActionSubType.SELF_FLOWER,
        payment: PaymentType.FROM_ALL_PLAYERS,
      },
      {
        name: 'Other flower',
        type: ActionSubType.OTHER_FLOWER,
        payment: PaymentType.FROM_SINGLE_PLAYER,
      },
    ],
  },
  {
    type: ActionType.AN_YAO,
    name: 'An Yao',
    chips: 6,
    isRare: false,
    subtypes: [
      {
        name: 'Animal',
        type: ActionSubType.ANIMAL,
        payment: PaymentType.FROM_ALL_PLAYERS,
      },
      {
        name: 'Self flower',
        type: ActionSubType.SELF_FLOWER,
        payment: PaymentType.FROM_ALL_PLAYERS,
      },
      {
        name: 'Other flower',
        type: ActionSubType.OTHER_FLOWER,
        payment: PaymentType.FROM_SINGLE_PLAYER,
      },
    ],
  },
  {
    type: ActionType.KANG,
    name: 'Kang',
    chips: 3,
    payment: PaymentType.FROM_ALL_PLAYERS,
    isRare: false,
  },
  {
    type: ActionType.AN_KANG,
    name: 'An Kang',
    chips: 6,
    payment: PaymentType.FROM_ALL_PLAYERS,
    isRare: false,
  },
  {
    type: ActionType.SAKURA,
    name: 'Sakura',
    chips: 3,
    payment: PaymentType.FROM_ALL_PLAYERS,
    isRare: false,
  },
  {
    type: ActionType.DOWNGRADED,
    name: 'Downgraded',
    payment: PaymentType.NONE,
    message: (
      <>
        Chehhhhhr fiiiik! <br /> <Emoji type={EmojiType.DEVIL} />
      </>
    ),
    isRare: false,
  },
  {
    type: ActionType.FLOWER_SET,
    name: 'Flower Set',
    chips: 6,
    payment: PaymentType.FROM_ALL_PLAYERS,
    isRare: true,
  },
  {
    type: ActionType.AN_FLOWER_SET,
    name: 'Flower set covered',
    chips: 12,
    payment: PaymentType.FROM_ALL_PLAYERS,
    isRare: true,
  },
  {
    type: ActionType.ALL_ANIMALS,
    name: 'All animals',
    chips: 9,
    payment: PaymentType.FROM_ALL_PLAYERS,
    isRare: true,
  },
  {
    type: ActionType.AN_ALL_ANIMALS,
    name: 'All animals covered',
    chips: 24,
    payment: PaymentType.FROM_ALL_PLAYERS,
    isRare: true,
  },
  {
    type: ActionType.AN_SAKURA,
    name: 'Sakura covered',
    chips: 6,
    payment: PaymentType.FROM_ALL_PLAYERS,
    isRare: true,
  },
  {
    type: ActionType.FAKE_WIN,
    name: 'Fake win',
    chips: 32,
    payment: PaymentType.TO_ALL_PLAYERS,
    isRare: true,
  },
];

const Action = () => {
  const history = useHistory();
  const location = useLocation();

  const [selectedPaymentAction, setSelectedPaymentAction] =
    useState<PaymentAction | null>(null);

  const [selectedActionWithSubtype, setSelectedActionWithSubtype] =
    useState<ActionWithSub | null>(null);

  const [noPayment, setNoPayment] = useState<NoPaymentAction | null>(null);

  if (selectedPaymentAction) {
    if (PaymentType.FROM_ALL_PLAYERS === selectedPaymentAction.payment) {
      return (
        <PayFromAllOtherPlayers
          actionType={selectedPaymentAction.type}
          chips={selectedPaymentAction.chips}
        />
      );
    }
    if (PaymentType.TO_ALL_PLAYERS === selectedPaymentAction.payment) {
      return (
        <PayToAllOtherPlayers
          actionType={selectedPaymentAction.type}
          chips={selectedPaymentAction.chips}
        />
      );
    }
    return (
      <PayFromSinglePlayer
        actionType={selectedPaymentAction.type}
        chips={selectedPaymentAction.chips}
      />
    );
  }

  if (noPayment) {
    return <NoPayment action={noPayment} />;
  }

  if (selectedActionWithSubtype) {
    return <SubAction action={selectedActionWithSubtype} />;
  }

  const next = (action: PlayerAction) => {
    if ((action as NoPaymentAction).message) {
      setNoPayment(action as NoPaymentAction);
      return;
    }
    if ((action as ActionWithSub).subtypes) {
      setSelectedActionWithSubtype(action as ActionWithSub);
      return;
    }

    setSelectedPaymentAction(action as PaymentAction);
  };

  return (
    <Box
      mx={2}
      height="100vh"
      justifyContent="space-between"
      display="flex"
      flexDirection="column"
    >
      <h1>Select an action</h1>
      <Box
        width={1}
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        alignItems="center"
        ml={-1}
      >
        {actions
          .filter((a) => !a.isRare)
          .map((a) => (
            <Button
              my={2}
              ml={2}
              width={135}
              height={135}
              fontSize={22}
              onClick={() => next(a)}
            >
              {a.name}
            </Button>
          ))}
        <Box ml={2} mb={1}>
          <Accordion>
            <AccordionSummary>Click for more options</AccordionSummary>
            <AccordionDetails>
              <Box display="flex" flexWrap="wrap">
                {actions
                  .filter((a) => a.isRare)
                  .map((a) => {
                    return (
                      <Button
                        my={2}
                        ml={2}
                        width={135}
                        height={135}
                        fontSize={22}
                        onClick={() => next(a)}
                      >
                        {a.name}
                      </Button>
                    );
                  })}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
      <Button
        width="100%"
        onClick={() => history.push(`${location.pathname}/win`)}
        flexGrow={0}
        mb={2}
      >
        Win
      </Button>
    </Box>
  );
};

export default Action;
