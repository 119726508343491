import { Box } from 'reflexbox';
import {
  Card,
  CardContent,
  CircularProgress,
  makeStyles,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useGame } from '../GameContext';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Button, useStyles } from '../common';
import { ActionType, Player, Seats, Wind, WindSymbols } from '../typings';
import classNames from 'classnames';
import InGameMenu from './InGameMenu';
import AsyncScreenTransition from '../AsyncScreenTransition';

const usePlayerStyles = makeStyles({
  tip: {
    fontFamily: 'Montserrat, helvetica, arial, sans-serif',
    fontSize: 17,
  },

  wind: {
    fontSize: 110,
  },
});

const WindButton = (props: any) => {
  const classes = makeStyles({
    windButton: {
      position: 'relative',
      zIndex: 2,
      '&::before': {
        fontFamily: 'Noto Sans SC',
        fontWeight: 600,
        content: `'${props.wind || ''}'`,
        position: 'absolute',
        display: 'block',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        fontSize: 95,
        zIndex: 1,
        opacity: 0.2,
      },
    },
  })();
  return <Button {...props} className={classes.windButton} />;
};

const PendingGame = () => {
  const history = useHistory();
  const { players, appendEvents, hasGameEnded, game } = useGame();

  const [isDraw, setIsDraw] = useState<boolean>(false);
  const [hasSubmittedDraw, setHasSubmittedDraw] = useState<
    { goNextTurn: boolean } | undefined
  >(undefined);

  const classes = { ...useStyles(), ...usePlayerStyles() };

  if (!game || !players) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  const recordDraw = async (goNextTurn: boolean) => {
    const id = uuidv4();
    await appendEvents({
      playerName: players[game.turn].name,
      playerId: players[game.turn].id,
      chips: 0,
      action: ActionType.DRAW,
      metadata: {
        goNextTurn,
      },
      id,
    });
    setIsDraw(false);
    setHasSubmittedDraw(undefined);
  };

  const enrichedPlayers: Array<Player & { wind?: number }> = players;
  let windIndex = 0;
  for (let i = Number(game.turn); i < 4 && windIndex < 4; i = (i + 1) % 4) {
    enrichedPlayers[i] = {
      ...enrichedPlayers[i],
      wind: windIndex,
    };
    windIndex++;
  }

  const playerWind =
    enrichedPlayers && game
      ? enrichedPlayers.find((p) => p.wind === game.wind)?.name
      : undefined;

  if (hasSubmittedDraw) {
    return (
      <AsyncScreenTransition
        asyncCall={async () => await recordDraw(hasSubmittedDraw.goNextTurn)}
        onForceContinue={async () => {
          if (hasSubmittedDraw.goNextTurn) {
            // await nextTurn(null);
          }
          setIsDraw(false);
          setHasSubmittedDraw(undefined);
        }}
      />
    );
  }

  return (
    <Box
      display="flex"
      height="100vh"
      flexDirection="column"
      justifyContent="space-between"
    >
      <InGameMenu />
      <Box className={classes.text} textAlign="center" width={1}>
        Wind <br />
        <span className={classNames(classes.wind, classes.neon)}>
          {WindSymbols[game.wind as Seats]}
        </span>
      </Box>

      {playerWind && (
        <Card className={classes.tip}>
          <CardContent>
            {playerWind} your wind is the round wind! 2 points leeeh!
          </CardContent>
        </Card>
      )}

      <Box
        width={1}
        display="flex"
        flexWrap="wrap"
        justifyContent="space-between"
      >
        {!isDraw &&
          enrichedPlayers?.map((p) => {
            return (
              <WindButton
                highlighted={p.wind === Wind.DONG}
                key={p.name}
                width={155}
                height={155}
                onClick={() => history.push(`/players/${p.name}`)}
                m={2}
                wind={WindSymbols[p.wind as Seats]}
              >
                {p.name}
              </WindButton>
            );
          })}
        {!hasGameEnded() && isDraw && (
          <>
            <Button
              m={2}
              onClick={() => setHasSubmittedDraw({ goNextTurn: false })}
            >
              Continue current turn
            </Button>

            <Button
              m={2}
              onClick={() => setHasSubmittedDraw({ goNextTurn: true })}
            >
              Go next turn?
            </Button>
          </>
        )}
        {!hasGameEnded() && !isDraw && (
          <Button onClick={() => setIsDraw(true)} witdh={1} m={2} height={100}>
            Draw
          </Button>
        )}

        {hasGameEnded() && (
          <Button onClick={() => history.push('/endGame')}>
            Game over - Click here
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default PendingGame;
