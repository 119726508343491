import { ReactElement } from 'react';
import { Database } from '../supabase/databaseTypes';

export type Seats = 0 | 1 | 2 | 3;

export type Players = Record<
  Seats,
  Database['public']['Tables']['users']['Row']['id']
>;

export type Game = Omit<
  Database['public']['Tables']['games']['Row'],
  'players'
> & {
  players: Players;
};

export type Player = {
  id: string;
  name: string;
};

export type Event = {
  playerName: Player['name'];
  playerId: Player['id'];
  chips: number;
  action: ActionType;
  metadata: Record<string, any> | null;
  id: string; // = grouping Id in DB
};

export enum Wind {
  DONG = 0,
  NAN = 1,
  XI = 2,
  BEI = 3,
}

export const WindSymbols = {
  [Wind.DONG]: '东',
  [Wind.NAN]: '南',
  [Wind.XI]: '西',
  [Wind.BEI]: '北',
};

export const Winds: Wind[] = [Wind.DONG, Wind.NAN, Wind.XI, Wind.BEI];

export enum PaymentType {
  FROM_SINGLE_PLAYER,
  FROM_ALL_PLAYERS,
  NONE,
  TO_ALL_PLAYERS,
}

export enum ActionType {
  WIN = 'WIN',
  LOST = 'LOST',
  STUCK = 'STUCK',
  DRAW = 'DRAW',
  YAO = 'YAO',
  AN_YAO = 'AN_YAO',
  KANG = 'KANG',
  AN_KANG = 'AN_KANG',
  SAKURA = 'SAKURA',
  AN_SAKURA = 'AN_SAKURA',
  FLOWER_SET = 'FLOWER_SET',
  AN_FLOWER_SET = 'AN_FLOWER_SET',
  ALL_ANIMALS = 'ALL_ANIMALS',
  AN_ALL_ANIMALS = 'AN_ALL_ANIMALS',
  DOWNGRADED = 'DOWNGRADED',
  FAKE_WIN = 'FAKE_WIN',
  RECONCILE = 'RECONCILE',
}

type ActionBase = {
  type: ActionType;
  name: string;
  isRare: boolean;
};

type PricedAction = ActionBase & {
  chips: number;
};

export type NoPaymentAction = ActionBase & {
  payment: PaymentType.NONE;
  message: ReactElement;
};

export type PaymentAction = PricedAction & {
  payment: PaymentType;
};

export enum ActionSubType {
  ANIMAL = 'ANIMAL',
  SELF_FLOWER = 'SELF_FLOWER',
  OTHER_FLOWER = 'OTHER_FLOWER',
}

export type SubAction = {
  name: string;
  type: ActionSubType;
  payment: PaymentType;
};

export type ActionWithSub = PricedAction & {
  subtypes: SubAction[];
};

export type PlayerAction = ActionWithSub | PaymentAction | NoPaymentAction;

export const points = [0, 1, 2, 4, 8, 16, 16, 16, 16, 16, 32];

export type RoundAction = PricedAction & {
  playerName: string;
  metadata: {
    points: number;
    selfdraw: boolean;
  };
};

export type Stats = {
  playerStats: PlayerStats;
  gameStats: GameStats;
};

export type PlayerStats = {
  [player: string]: SinglePlayerStats;
};

export type SinglePlayerStats = {
  actions: Record<string, number | Record<string, number>>;
  results: {
    points: number;
    chips: number;
    wins: number;
    shooter: [number];
    stuck: number;
    winsWithPoints: [{ selfdraw: number; regular: number }];
  };
  chipsByGame?: [number];
  duration?: number;
};

export type GameStats = {
  duration: number;
  turnCount: number;
  drawCount: number;
};

// TODO: remove - legacy from firebase
export type Group = {
  id: string;
};

export type User = {
  id: string;
  groupId: string;
  name: string;
};
