import { Button } from './common';
import { useHistory } from 'react-router';
import { useEffect, useState } from 'react';

import { Bar } from 'react-chartjs-2';
import { Emoji, EmojiType } from './emojis';
import { WithLoader } from './common/WithLoader';
import { Box } from 'reflexbox';
import { useAuth } from './AuthContext';
import { getChipsByPlayer as rpcGetChipsByPlayer } from './common/supabase';
import { DatePicker } from './common/DatePicker';

export default () => {
  const history = useHistory();
  const { group } = useAuth().getAuthContext();
  const { goBack } = history;

  const [graphData, setGraphData] = useState<
    { name: string; chips: number }[] | undefined
  >(undefined);

  const [selectedDate, setSelectedDate] = useState<string>(
    `${new Date().getFullYear()}-01-01`
  );

  useEffect(() => {
    if (!selectedDate) {
      return;
    }
    const getChipsByPlayer = async () => {
      const chips = await rpcGetChipsByPlayer(new Date(selectedDate), group.id);
      setGraphData(chips);
    };
    getChipsByPlayer();
  }, [selectedDate]);

  const backgroundColor = [
    'rgba(75, 192, 192, 0.2)',
    'rgba(54, 162, 235, 0.2)',
    'rgba(255, 206, 86, 0.2)',
    'rgba(235, 135, 30, 0.2)',
    'rgba(255, 99, 132, 0.2)',
    'rgba(235, 30, 30, 0.2)',
    'rgba(140, 30, 235, 0.2)',
  ];
  const borderColor = [
    'rgba(75, 192, 192, 1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(235, 135, 30, 1)',
    'rgba(255, 99, 132, 1)',
    'rgba(235, 30, 30, 1)',
    'rgba(140, 30, 235, 1)',
  ];

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      yAxes: {
        beginAtZero: true,
        grid: {
          display: true,
          lineWidth: (context: any) => (context.tick.value === 0 ? 2 : 0),
        },
      },
    },
  };

  const winner = () => {
    return graphData && graphData[0];
  };

  const loser = () => {
    return graphData && graphData[graphData.length - 1];
  };

  return (
    <WithLoader
      body={
        <Box
          height="100%"
          display="flex"
          justifyContent="space-evenly"
          flexDirection="column"
        >
          <DatePicker onChange={setSelectedDate} value={selectedDate} />
          <Box>
            <Box display="flex" alignItems="center" flexDirection="row">
              <Box display="flex" flexDirection="row">
                <h1 style={{ margin: 0, marginRight: 2 }}>Congrats</h1>
                <Emoji type={EmojiType.SPARKLES} size={40} />
              </Box>

              <Box
                display="flex"
                mt={-40}
                justifyItems="center"
                alignItems="center"
                flexDirection="column"
              >
                <Emoji type={EmojiType.CROWN} size={40} />
                <h1 style={{ margin: 0, marginLeft: 4, marginRight: 4 }}>
                  {winner()?.name}
                </h1>
              </Box>

              <Box display="flex" flexDirection="row">
                <Emoji type={EmojiType.SPARKLES} size={40} />
              </Box>
            </Box>

            <span
              style={{ fontStyle: 'italic' }}
            >{`You're the biggest winner (so far!) with $${
              (winner()?.chips || 0) / 2
            } won!`}</span>
          </Box>

          <Box>
            <Box display="flex" alignItems="center" flexDirection="row">
              <h1 style={{ margin: 0, marginRight: 8 }}>
                Sorry {loser()?.name}
              </h1>
              <Emoji type={EmojiType.PLEADING_FACE} size={40} />
            </Box>
            <span
              style={{ fontStyle: 'italic' }}
            >{`You're the biggest loser (so far!) with $${-(
              (loser()?.chips || 0) / 2
            )} lost!`}</span>
          </Box>

          <Box>
            <h1 style={{ margin: 0 }}>All players:</h1>
            <Bar
              data={{
                labels: graphData?.map((d) => d.name),
                datasets: [
                  {
                    data: graphData?.map((d) => d.chips / 2),
                    borderColor,
                    backgroundColor,
                    borderWidth: 2,
                  },
                ],
              }}
              type="bar"
              options={options}
            />
          </Box>
        </Box>
      }
      footer={
        <Button size={12} flexGrow={0} onClick={() => goBack()}>
          Go back
        </Button>
      }
      isLoading={!graphData}
    />
  );
};
