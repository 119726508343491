import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { Box } from '@material-ui/core';

export const DatePicker = ({
  onChange,
  value,
}: {
  onChange: (newValue: string) => void;
  value: string;
}) => {
  return (
    <Box display="flex" flexDirection="column" marginBottom={3}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <h1 style={{ margin: 0, marginLeft: 8, marginTop: 4 }}>
          See data since:
        </h1>
        <MobileDatePicker
          value={dayjs(value)}
          onChange={(newValue) => {
            if (newValue) {
              onChange(newValue?.toISOString());
            }
          }}
          maxDate={dayjs(new Date().toISOString())}
          sx={{
            '& .MuiInputBase-root': {
              color: 'white',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              'border-color': 'grey',
            },
          }}
        />
      </LocalizationProvider>
    </Box>
  );
};
