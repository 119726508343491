import { Box } from 'reflexbox';
import { Players, Seats, WindSymbols } from '../typings';
import { Button, CenteredContentBox, useStyles } from '../common';
import { useGame } from '../GameContext';
import { Grid, makeStyles } from '@material-ui/core';
import classNames from 'classnames';

import arrow from '../assets/left_circle_arrow.png';
import { useState } from 'react';
import AsyncScreenTransition from '../AsyncScreenTransition';
import { useAuth } from '../AuthContext';

const useComponentStyles = makeStyles({
  wind: {
    fontSize: 45,
  },

  table: {
    height: 120,
    width: 120,
    borderRadius: 3,
    border: '#96ef48 2px solid',
  },

  player: {
    color: '#586EE1',
    fontSize: 25,
  },

  arrowIcon: {
    width: 75,
    height: 75,
  },
});

const CheckSeating = ({
  players,
  onReject,
}: {
  players: Players;
  onReject: () => void;
}) => {
  const { startGame } = useGame();
  const { group, groupUsers } = useAuth().getAuthContext();
  const styles = { ...useComponentStyles(), ...useStyles() };
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);

  const playerSeating: Seats[] = [0, 1, 3, 2];

  const submitPlayers = async () => {
    const now = new Date();
    const getZeroPrefixed = (val: number) => {
      if (val < 10) {
        return `0${val}`;
      }
      return val;
    };
    const game = {
      id: `${group.id}_${getZeroPrefixed(now.getDate())}${getZeroPrefixed(
        now.getMonth() + 1
      )}${getZeroPrefixed(now.getFullYear())}_${getZeroPrefixed(
        now.getHours()
      )}${getZeroPrefixed(now.getMinutes())}${getZeroPrefixed(
        now.getSeconds()
      )}`,
      group_id: group.id,
      created_at: new Date().toISOString(),
      ended_at: null,
      is_game_over: false,
      wind: 0 as Seats,
      turn: 0 as Seats,
      players,
    };

    await startGame(game);
  };

  if (hasSubmitted) {
    return (
      <AsyncScreenTransition
        asyncCall={submitPlayers}
        onForceContinue={async () => {}}
      />
    );
  }

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      height="100vh"
      flexDirection="column"
      p={2}
    >
      <Box p={2}>
        <h1>Is the seating ok ?</h1>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={4} />
        {playerSeating.map((s) => (
          <>
            <Grid item xs={4}>
              <CenteredContentBox flexDirection="column">
                <div className={classNames(styles.neon, styles.wind)}>
                  {WindSymbols[s]}
                </div>
                <div className={styles.player}>
                  {groupUsers.find((u) => u.id === players[s])?.name}
                </div>
              </CenteredContentBox>
            </Grid>
            <Grid item xs={4}>
              {s === playerSeating[1] ? (
                <CenteredContentBox className={styles.table}>
                  <img
                    className={styles.arrowIcon}
                    src={arrow}
                    alt="table-drawing"
                  />
                </CenteredContentBox>
              ) : null}
            </Grid>
          </>
        ))}
      </Grid>

      <Box>
        <Button
          mb={2}
          flexGrow={0}
          fullWidth
          onClick={() => setHasSubmitted(true)}
        >
          Start game
        </Button>
        <Button
          flexGrow={0}
          fullWidth
          onClick={() => {
            onReject();
          }}
          className={styles.negativeButton}
        >
          Reshuffle
        </Button>
      </Box>
    </Box>
  );
};

export default CheckSeating;
