import { ReactNode } from 'react';
import { useGame } from '../GameContext';
import { Box } from 'reflexbox';
import { useStyles } from '.';

const Summary = ({ children }: { children: ReactNode }) => {
  const { backHome } = useGame();
  const classes = useStyles();

  return (
    <Box
      display="flex"
      justifyContent="space-evenly"
      height="100vh"
      flexDirection="column"
      onClick={backHome}
    >
      <Box
        className={classes.textLeft}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        flexGrow={1}
      >
        {children}
      </Box>
      <span style={{ fontStyle: 'italic', padding: 8 }}>
        Click anywhere to continue
      </span>
    </Box>
  );
};

export default Summary;
