import styled from 'styled-components';
import { Box } from 'reflexbox';

type BtnProps = {
  readonly highlighted?: boolean;
  readonly fontSize?: number;
  readonly width?: number;
  readonly flexGrow?: number;
  readonly borderColor?: string;
  readonly disabled?: boolean;
};

const Btn = styled(Box)<BtnProps>`
  color: ${({ highlighted, disabled }) =>
    disabled ? 'grey' : highlighted ? '#586EE1' : 'white'};
  border: #96ef48 2px solid;
  border-color: ${({ borderColor, disabled }) =>
    disabled ? 'grey' : borderColor ? borderColor : '#96ef48'};
  border-radius: 7px;
  text-align: center;
  padding: 20px 5px;
  font-weight: 200;
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '26px')};
  text-transform: none;
  width: ${({ width }) => `${width}px` || 'auto'};
  flex-grow: ${({ flexGrow }) =>
    flexGrow !== undefined ? `${flexGrow}` : '1'};
  box-sizing: border-box;
  cursor: pointer;
  overflow-wrap: anywhere;
`;

export const Button = (props: any) => {
  const { children, size, ...buttonProps } = props;
  return (
    <Btn
      variant="outlined"
      color="primary"
      display="flex"
      justifyContent="center"
      alignItems="center"
      {...buttonProps}
    >
      {children}
    </Btn>
  );
};
