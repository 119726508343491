import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  styled,
} from '@material-ui/core';

export const Select = (props: any) => {
  const StyledSelect = styled(MuiSelect)({
    color: 'white',
    border: '#96EF48 2px solid',
    padding: '0 !important',
    borderRadius: 7,
    textAlign: 'center',
    fontWeight: 200,
    fontSize: 20,
    textTransform: 'none',
    width: props.width ? props.width : 'auto',
    flexGrow: 1,
    boxSizing: 'border-box',
    cursor: 'pointer',
    overflowWrap: 'anywhere',
  });

  const StyledInputLabel = styled(InputLabel)({
    fontSize: 15,
    position: 'unset',
    paddingLeft: 10,
    paddingTop: 5,
  });

  const { children, label, ...selectProps } = props;
  return (
    <FormControl fullWidth>
      <StyledInputLabel>{label}</StyledInputLabel>
      <StyledSelect
        fullWidth
        variant="outlined"
        color="primary"
        display="flex"
        justifyContent="center"
        alignItems="center"
        {...selectProps}
      >
        {children}
      </StyledSelect>
    </FormControl>
  );
};
