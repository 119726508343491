import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import React from 'react';
import PendingGame from './PendingGame';
import Action from './GameActions';
import StartGame from './StartGame';
import GameStats from './GameStats';
import Win from './Win';
import Invite from './Invite';
import Home from './Home';
import UserStats from './UserStats';
import GroupStats from './GroupStats';
import MainMenu from './MainMenu';
import EndGame from './EndGame';
import Undo from './Undo';
import { GameContextProvider } from './GameContext';
import {
  createMuiTheme,
  CssBaseline,
  ThemeProvider,
  useMediaQuery,
} from '@material-ui/core';
import { AuthContextProvider } from './AuthContext';

export default function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: prefersDarkMode ? 'dark' : 'dark',
        },
        overrides: {
          MuiCssBaseline: {
            '@global': {
              body: {
                WebkitFontSmoothing: 'auto',
                fontFamily: 'Montserrat,helvetica,arial,sans-serif',
              },
            },
          },
        },
      }),
    [prefersDarkMode]
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <AuthContextProvider>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/userStats/:id">
              <UserStats />
            </Route>
            <Route exact path="/groupStats">
              <GroupStats />
            </Route>
            <Route exact path="/invite/:groupId">
              <Invite />
            </Route>
            <GameContextProvider>
              <Route exact path="/menu">
                <MainMenu />
              </Route>
              <Route exact path="/start">
                <StartGame />
              </Route>
              <Route exact path="/players">
                <PendingGame />
              </Route>
              <Route exact path="/players/:id">
                <Action />
              </Route>
              <Route exact path="/players/:id/win">
                <Win />
              </Route>
              <Route exact path="/gameStats">
                <GameStats />
              </Route>
              <Route exact path="/endGame">
                <EndGame />
              </Route>
              <Route exact path="/undo">
                <Undo />
              </Route>
            </GameContextProvider>
          </Switch>
        </AuthContextProvider>
      </Router>
    </ThemeProvider>
  );
}
