import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Group, User } from './typings';
import { executeFunction, initSupabase } from './common/supabase';
import { WithCircularLoader } from './common/WithCircularLoader';
import { DBUser } from '../supabase/databaseTypes';

type Min4Array = {
  0: User;
  1: User;
  2: User;
  4: User;
} & Array<User>;

type AuthContextType = {
  isSignedIn: boolean;
  signIn: () => Promise<void>;
  getAuthContext: () => {
    loggedInUser: User;
    group: Group;
    groupUsers: Min4Array;
  };
};

const AuthContext = React.createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error('Undefined context');
  }
  return context;
};

export const AuthContextProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const history = useHistory();
  const location = useLocation();
  const [loggedInUser, setLoggedInUser] = useState<User | null>(null);
  const [group, setGroup] = useState<Group | null>(null);
  const [users, setUsers] = useState<User[]>([]);
  const [isExcludedOfLoggedIn, setIsExcludedOfLoggedIn] = useState(false);

  const isSignedIn = !!loggedInUser && !!group && users.length >= 4;

  const getAuthContext = () => {
    return {
      loggedInUser: loggedInUser as User,
      group: group as Group,
      groupUsers: users as Min4Array,
    };
  };

  useEffect(() => {
    setIsExcludedOfLoggedIn(location.pathname === '/');
  }, [location, location.pathname]);

  useEffect(() => {
    const getSession = async () => {
      const { data } = await initSupabase().auth.getSession();

      if (!data.session) {
        history.push('/');
        return;
      }

      if (data.session) {
        const registeredUsers = await executeFunction<DBUser[]>('retrieveBy', {
          entityName: 'users',
          fields: [{ name: 'email', value: data.session.user.email }],
        });

        if (registeredUsers?.length !== 1) {
          alert("You're not a registered user");
          return;
        }
        const registeredUser = registeredUsers[0];
        setLoggedInUser({
          ...registeredUser,
          groupId: registeredUser.group_id,
        });
        setGroup({ id: registeredUser.group_id });

        const fetchUsers = await executeFunction<User[]>('retrieveBy', {
          entityName: 'users',
          fields: [{ name: 'group_id', value: registeredUser.group_id }],
        });
        setUsers(fetchUsers);
      }
    };
    getSession();
  }, []);

  const signIn = async () => {
    const { error } = await initSupabase().auth.signInWithOAuth({
      provider: 'google',
    });
    if (error) {
      alert('Something went wrong during your sign in');
    }
  };

  const value = {
    signIn,
    isSignedIn,
    getAuthContext,
  };

  return (
    <AuthContext.Provider value={value}>
      <WithCircularLoader isLoading={!isSignedIn && !isExcludedOfLoggedIn}>
        <>{children}</>
      </WithCircularLoader>
    </AuthContext.Provider>
  );
};
