import { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  makeStyles,
  MenuItem,
  CircularProgress,
} from '@material-ui/core';
import { useGame } from './GameContext';
import { Bar } from 'react-chartjs-2';
import { Select } from './common';
import { Box } from 'reflexbox';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { actions } from './GameActions/Action';
import {
  ActionType,
  ActionWithSub,
  Event,
  Player,
  PlayerAction,
  PlayerStats,
  SubAction,
} from './typings';
import { executeFunction } from './common/supabase';
import { DBGameEvent } from '../supabase/databaseTypes';

const useStyles = makeStyles({
  selectItem: {
    margin: 8,
    padding: 5,
  },
  back: {
    fontSize: 40,
    position: 'absolute',
    right: 0,
    top: 0,
    cursor: 'pointer',
  },
});

type DataRepresentation = {
  backgroundColor: string[];
  borderColor: string[];
  borderWidth: number;
  data: number[];
  label: string;
};

type Dataset = {
  type: string;
  labels: string[];
  datasets: DataRepresentation[];
};

const excludeFromResults = ['winsWithPoints'];
const backgroundColor = [
  'rgba(255, 99, 132, 0.2)',
  'rgba(54, 162, 235, 0.2)',
  'rgba(255, 206, 86, 0.2)',
  'rgba(75, 192, 192, 0.2)',
];
const borderColor = [
  'rgba(255, 99, 132, 1)',
  'rgba(54, 162, 235, 1)',
  'rgba(255, 206, 86, 1)',
  'rgba(75, 192, 192, 1)',
];
const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          stepSize: 1,
          beginAtZero: true,
        },
      },
    ],
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};

export default () => {
  const classes = useStyles();
  const { backHome, players, game } = useGame();

  const [playerStats, setPlayerStats] = useState<PlayerStats | undefined>(
    undefined
  );
  const [graphs, setGraphs] = useState<string[]>([]);
  const [datasets, setDatasets] = useState<Dataset[] | undefined>();
  const [data, setData] = useState<Dataset | undefined>();
  const [selectedGraph, setSelectedGraph] = useState<string | undefined>();

  // TODO: simplify this horror
  const getPlayerStatsFromEvents = (events: Event[]): PlayerStats => {
    const createActionStats = () =>
      actions.reduce(
        (actionsCount: any, action: PlayerAction): any => ({
          ...actionsCount,
          // If it's an action wyth subtype, create an object like:
          // { action: { subaction1: 0, subaction2: 0 }}
          [action.type]:
            (action as ActionWithSub)?.subtypes?.reduce(
              (actionCount: any, subaction: SubAction): any => ({
                ...actionCount,
                [subaction.type]: 0,
              }),
              {}
            ) ?? 0,
        }),
        {}
      );

    const playerStats = players.reduce(
      (stats: PlayerStats, { id }: Player): any => {
        return {
          ...stats,
          [id]: {
            results: {
              chips: 0,
              points: 0,
              wins: 0,
              shooter: new Array(11).fill(0),
              stuck: 0,
              winsWithPoints: new Array(11).fill(undefined).map(() => ({
                regular: 0,
                selfdraw: 0,
              })),
            },
            actions: createActionStats(),
          },
        };
      },
      {}
    );

    events.forEach((event) => {
      playerStats[event.playerId].results.chips += event.chips;
      if (event.action === ActionType.WIN) {
        playerStats[event.playerId].results.wins += 1;
        playerStats[event.playerId].results.points += event.metadata?.points;
        if (event.metadata?.points) {
          if (event.metadata?.selfdraw) {
            playerStats[event.playerId].results.winsWithPoints[
              event.metadata.points
            ].selfdraw += 1;
          } else {
            playerStats[event.playerId].results.winsWithPoints[
              event.metadata.points
            ].regular += 1;
          }
        }
      }

      if (event.action === ActionType.LOST && event.metadata?.shooter) {
        playerStats[event.playerId].results.shooter[event.metadata.points] += 1;
      }

      if (event.action === ActionType.STUCK) {
        playerStats[event.playerId].results.stuck += 1;
      }

      actions.forEach((a) => {
        if (event.action === a.type) {
          if (
            (playerStats[event.playerId].actions[a.type] as Record<
              string,
              number
            >) &&
            event.metadata?.subtype
          ) {
            const record = playerStats[event.playerId].actions[
              a.type
            ] as Record<string, number>;
            const base = record[event.metadata.subtype] || 0;
            (
              playerStats[event.playerId].actions[a.type] as Record<
                string,
                number
              >
            )[event.metadata.subtype] = base + (event.chips >= 0 ? 1 : 0);
            return;
          }
          const base =
            (playerStats[event.playerId].actions[a.type] as number) || 0;
          playerStats[event.playerId].actions[a.type] =
            base + (event.chips >= 0 ? 1 : 0);
        }
      });
    });

    return playerStats;
  };

  useEffect(() => {
    const apiCall = async () => {
      const events = await executeFunction<DBGameEvent[]>('retrieveBy', {
        entityName: 'game_events',
        fields: [
          {
            name: 'game_id',
            value: game.id,
          },
        ],
      });
      const domainEvents = events.map((e) => ({
        playerName:
          players.find((p) => p.id === e.player_id)?.name || e.player_id,
        playerId: e.player_id,
        chips: e.chips,
        action: e.action_type as ActionType,
        metadata: e.action_data as Event['metadata'],
        id: e.id,
      }));
      setPlayerStats(getPlayerStatsFromEvents(domainEvents));
    };
    apiCall();
  }, [game]);

  useEffect(() => {
    if (!playerStats) {
      return;
    }
    const localGraphs = Object.keys(Object.values(playerStats)[0].results);
    setGraphs(localGraphs);
    if (!selectedGraph) {
      setSelectedGraph(localGraphs[0]);
    }

    const localDatasets = localGraphs.map((v) => state(v));
    setDatasets(localDatasets);
  }, [playerStats]);

  const assignColor = () => {
    if (!playerStats) {
      return;
    }
    let index = 0;
    return Object.keys(playerStats).map((playerId) => {
      const a = {
        playerId,
        background: backgroundColor[index],
        border: borderColor[index],
      };
      index = index + 1;
      return a;
    });
  };
  const colorAssignments = assignColor();

  const state = (dataType: string) => {
    if (!playerStats) {
      throw new Error();
    }
    return {
      type: dataType,
      labels: Object.keys(playerStats).map<string>((playerId) => {
        const playerName = players.find((pl) => pl.id === playerId)?.name;
        if (!playerName) {
          throw new Error('Undefined player name');
        }
        return playerName;
      }),
      datasets: [
        {
          label: dataType,
          backgroundColor,
          borderColor,
          borderWidth: 2,
          data: Object.values(playerStats).map((v) => {
            const val = (v as any).results[dataType];
            // If val is an array we need to aggregate the data to only get 1 number/person
            if (Array.isArray(val)) {
              return val.reduce((prev, current) => prev + current, 0);
            }
            return val;
          }),
        },
      ],
    };
  };

  const actionsDataset = (
    dataType: string,
    condition: (param: PlayerAction) => boolean
  ) => {
    if (!playerStats || !colorAssignments) {
      throw new Error();
    }
    const actionsApplicable = actions
      .filter((a) => condition(a))
      .map((a) => ({ name: a.name, type: a.type }));

    return {
      type: dataType,
      labels: actionsApplicable.flatMap((a) => a.name),
      datasets: Object.keys(playerStats).map((playerId) => {
        const color = colorAssignments.find((c) => c.playerId === playerId);
        if (!color) {
          throw new Error();
        }
        const playerName = players.find((pl) => pl.id === playerId)?.name;
        if (!playerName) {
          throw new Error('Undefined player name');
        }

        return {
          label: playerName,
          backgroundColor: [color.background],
          borderColor: [color.border],
          borderWidth: 1,
          data: Object.entries(playerStats[playerId].actions)
            .filter(([k, v]) =>
              actionsApplicable.flatMap((a) => a.type.toString()).includes(k)
            )
            .map(([k, v]) => {
              if (Number.isInteger(v)) {
                return v;
              }
              return Object.values(v).reduce(
                (val: number, currentVal: number): number => {
                  return val + currentVal;
                },
                0
              );
            }),
        };
      }),
    };
  };

  useEffect(() => {
    if (!selectedGraph || !datasets) {
      return;
    }
    if (selectedGraph === 'actions' || selectedGraph === 'rareActions') {
      const predicate = (a: PlayerAction) =>
        selectedGraph === 'actions' ? !a.isRare : a.isRare;
      setData(actionsDataset(selectedGraph, predicate));
      return;
    }
    setData(datasets.filter((d) => d.type === selectedGraph)[0]);
  }, [selectedGraph, datasets]);

  if (!data) {
    return (
      <Box
        display="flex"
        alignItems="center"
        height="100vh"
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!playerStats || !colorAssignments) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <ArrowBackIcon className={classes.back} onClick={backHome} />
      <Box m={2} mt={5}>
        <Select
          label="Graph"
          value={data.type}
          onChange={(event: any) =>
            setSelectedGraph(event.target.value as string)
          }
        >
          {graphs.map((graph) => {
            return (
              !excludeFromResults.includes(graph) && (
                <MenuItem value={graph}>
                  <span className={classes.selectItem}>{graph}</span>
                </MenuItem>
              )
            );
          })}
          <MenuItem value="actions">
            <span className={classes.selectItem}>actions</span>
          </MenuItem>
          <MenuItem value="rareActions">
            <span className={classes.selectItem}>rare actions</span>
          </MenuItem>
        </Select>
      </Box>
      <Box m={1} mb={4}>
        <Bar data={data} type="bar" options={options} />
      </Box>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Player</TableCell>
              <TableCell align="right">Chips</TableCell>
              <TableCell align="right">Points</TableCell>
              <TableCell align="right">Wins</TableCell>
              <TableCell align="right">Shooter</TableCell>
              <TableCell align="right">Stuck</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(playerStats).map(([playerId, user]) => (
              <TableRow key={playerId}>
                <TableCell component="th" scope="row">
                  <span
                    style={{
                      borderBottom: `3px solid ${
                        colorAssignments.find((c) => c.playerId === playerId)
                          ?.border ?? 'white'
                      }`,
                    }}
                  >
                    {players.find((pl) => pl.id === playerId)?.name}
                  </span>
                </TableCell>
                <TableCell align="right">{user.results.chips}</TableCell>
                <TableCell align="right">{user.results.points}</TableCell>
                <TableCell align="right">{user.results.wins}</TableCell>
                <TableCell align="right">
                  {user.results.shooter.reduce((prev, current) => {
                    return prev + current;
                  }, 0)}
                </TableCell>
                <TableCell align="right">{user.results.stuck}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
