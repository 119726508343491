import { makeStyles, Menu, MenuItem } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useGame } from '../GameContext';
import { useState } from 'react';
import { useStyles } from '../common';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import UndoIcon from '@material-ui/icons/Undo';
import HomeIcon from '@material-ui/icons/Home';
import AssignmentIcon from '@material-ui/icons/Assignment';

const usePlayerStyles = makeStyles({
  stats: {
    fontSize: 40,
    position: 'absolute',
    right: 0,
    cursor: 'pointer',
  },

  icon: {
    marginRight: 20,
  },
});

const InGameMenu = () => {
  const history = useHistory();
  const { game } = useGame();

  if (!game) {
    throw new Error();
  }

  const classes = { ...useStyles(), ...usePlayerStyles() };

  const [anchorMenuElement, setAnchorMenuElement] = useState<
    undefined | HTMLElement
  >(undefined);
  const openMenu = (event: any) => {
    setAnchorMenuElement(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenuElement(undefined);
  };

  return (
    <>
      <MenuIcon className={classes.stats} onClick={(e) => openMenu(e)} />
      <Menu
        id="simple-menu"
        anchorEl={anchorMenuElement}
        keepMounted
        open={!!anchorMenuElement}
        onClose={closeMenu}
      >
        <MenuItem
          onClick={() => {
            history.push(`/gameStats`);
          }}
        >
          <EqualizerIcon className={classes.icon} />
          Stats
        </MenuItem>
        <MenuItem
          onClick={() => {
            history.push('/undo');
          }}
        >
          <UndoIcon className={classes.icon} />
          Undo
        </MenuItem>
        <MenuItem onClick={() => history.push(`/endGame`)}>
          <CloseIcon className={classes.icon} />
          End game
        </MenuItem>
        <hr />
        <MenuItem
          onClick={() => {
            history.push('/menu');
          }}
        >
          <HomeIcon className={classes.icon} />
          Home
        </MenuItem>
        <MenuItem
          component="a"
          href="https://docs.google.com/document/d/18mKf8Pe-GhIqoya-LHym5iljnbLhpcJE89hYpucjp3c/"
          target="_blank"
        >
          <AssignmentIcon className={classes.icon} />
          House rules
        </MenuItem>
      </Menu>
    </>
  );
};

export default InGameMenu;
