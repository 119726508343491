import { useHistory } from 'react-router';
import { Box } from 'reflexbox';
import { Button } from './common';
import { ReactNode, useEffect, useState } from 'react';
import { executeFunction } from './common/supabase';
import { useAuth } from './AuthContext';
import { WithLoader } from './common/WithLoader';
import { Emoji, EmojiType } from './emojis';
import { Line } from 'react-chartjs-2';
import { DBChipsByGame } from '../supabase/databaseTypes';
import { PostgrestFilterBuilder } from '@supabase/postgrest-js';
import { DatePicker } from './common/DatePicker';

type Data = {
  accumulatedMoney: number[];
  labels: string[];
  chipsByGame: number[];
  max: number;
  min: number;
  maxWin: number;
  maxLoss: number;
};

export default () => {
  const history = useHistory();
  const { goBack } = history;
  const { loggedInUser } = useAuth().getAuthContext();
  const [selectedDate, setSelectedDate] = useState<string>(
    `${new Date().getFullYear()}-01-01`
  );
  const [data, setData] = useState<Data | null>(null);

  useEffect(() => {
    if (!selectedDate) {
      return;
    }
    const apiCall = async () => {
      const chipsByGame = await executeFunction<DBChipsByGame[]>('retrieveBy', {
        entityName: 'chips_by_game',
        fields: [
          {
            name: 'group_id',
            value: loggedInUser.groupId,
          },
          {
            name: 'player_id',
            value: loggedInUser.id,
          },
          {
            builder: (builder: PostgrestFilterBuilder<any, any, any>) =>
              builder.gte('created_at', selectedDate),
          },
        ],
      });
      processData(chipsByGame);
    };
    apiCall();
  }, [selectedDate]);

  const processData = (chipsByGame: DBChipsByGame[]) => {
    if (!chipsByGame) {
      throw new Error('chipsByGame state must be set');
    }
    const sortedData = chipsByGame.sort((a, b) =>
      new Date(a.created_at) > new Date(b.created_at) ? 1 : -1
    );
    const labels = sortedData.map((val) => {
      const createdAt = new Date(val.created_at);
      return `${createdAt.getDate()}/${createdAt.getMonth() + 1}`;
    });

    const accumulatedMoney: Array<number> = [];
    const chipsByGameOnly = sortedData.map((e) => e.chips);
    chipsByGameOnly?.reduce((prev, current) => {
      const val = prev + current / 2;
      accumulatedMoney.push(val);
      return val;
    }, 0);
    const lossesOnly = chipsByGameOnly.filter((e) => e < 0) || [];
    setData({
      labels,
      chipsByGame: chipsByGameOnly,
      accumulatedMoney,
      min: Math.min(...accumulatedMoney),
      max: Math.max(...accumulatedMoney),
      maxLoss: lossesOnly.length > 0 ? Math.min(...lossesOnly) / 2 : 0,
      maxWin: Math.max(...(chipsByGameOnly || [])) / 2,
    });
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      yAxes: {
        beginAtZero: true,
        grid: {
          display: true,
          lineWidth: (context: any) => (context.tick.value === 0 ? 2 : 0),
          color: '#5CE708',
        },
      },
      xAxes: {
        beginAtZero: true,
        grid: {
          display: true,
          lineWidth: (context: any) => (context.tick.value === 0 ? 2 : 0),
          color: '#5CE708',
        },
      },
    },
  };

  const legendRow = (left: ReactNode, right: ReactNode) => (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-around"
      mb={1}
    >
      <Box display="flex" alignContent="center">
        {left}
      </Box>
      <Box display="flex" alignContent="center">
        {right}
      </Box>
    </Box>
  );

  return (
    <WithLoader
      body={
        <Box
          height="100%"
          display="flex"
          justifyContent="space-evenly"
          flexDirection="column"
        >
          {data?.chipsByGame.length === 0 ? (
            <h1>No data available for this period</h1>
          ) : (
            <>
              <DatePicker onChange={setSelectedDate} value={selectedDate} />
              <Box>
                <Box display="flex" alignItems="center">
                  <Emoji type={EmojiType.ABACUS} size={25} />
                  <h1 style={{ margin: 0, marginLeft: 8 }}>
                    Games played: {data?.chipsByGame.length}
                  </h1>
                </Box>
                <Box display="flex" alignItems="center" marginTop={2}>
                  <Emoji type={EmojiType.MONEY} size={25} />
                  <h1 style={{ margin: 0, marginLeft: 8 }}>
                    Total: SGD{' '}
                    {data
                      ? data.accumulatedMoney[data.accumulatedMoney.length - 1]
                      : 0}
                  </h1>
                </Box>
              </Box>

              <Box>
                <h1 style={{ margin: 0 }}>
                  <Emoji type={EmojiType.HOURGLASS} size={25} /> Over time
                </h1>
                {legendRow(
                  <>
                    <Emoji
                      type={EmojiType.PLEADING_FACE}
                      size={20}
                      styles={{ marginRight: 4 }}
                    />
                    <>Biggest loss: ${data?.maxLoss}</>
                  </>,
                  <>
                    <Emoji
                      type={EmojiType.MONEY_FACE}
                      size={20}
                      styles={{ marginRight: 4 }}
                    />
                    <>Biggest win: ${data?.maxWin}</>
                  </>
                )}
                {legendRow(
                  <>
                    <Emoji
                      type={EmojiType.ARROW_DOWN_RIGHT}
                      size={20}
                      styles={{ marginRight: 4 }}
                    />
                    <>Min total: ${data?.min}</>
                  </>,
                  <>
                    <Emoji
                      type={EmojiType.ARROW_UP_RIGHT}
                      size={20}
                      styles={{ marginRight: 4 }}
                    />
                    <>Max total: ${data?.max}</>
                  </>
                )}

                <Line
                  type="line"
                  data={{
                    labels: data?.labels,
                    datasets: [
                      {
                        data: data?.accumulatedMoney,
                        borderColor: 'rgb(159, 102, 231)',
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                      },
                    ],
                  }}
                  options={options}
                />
              </Box>
            </>
          )}
        </Box>
      }
      footer={
        <Button size={12} mt={3} flexGrow={0} onClick={() => goBack()}>
          Go back
        </Button>
      }
      isLoading={!data}
    />
  );

  /*
import { Button, useStyles } from './common';
import { useHistory, useLocation } from 'react-router';
import { ReactNode, useEffect, useState } from 'react';
import { getUser } from './common/firebase';
import { SinglePlayerStats } from './typings';

import { WithLoader } from './common/WithLoader';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from '@material-ui/core';
import { Emoji, EmojiType } from './emojis';
import { Line } from 'react-chartjs-2';
import { parseMilliseconds } from './common/utils';


  const history = useHistory();

  const { goBack } = history;
  const location = useLocation();
  const classes = useStyles();

  const [stats, setStats] = useState<SinglePlayerStats | undefined>(undefined);
  const [gameIdPlayed, setGameIdPlayed] = useState<[string] | undefined>(
    undefined
  );
  const [accumulatedChips, setAccumulatedChips] = useState<
    Array<number> | undefined
  >(undefined);
  const [labels, setLabels] = useState<string[] | undefined>(undefined);
  const [minAccumulatedValue, setMinAccumulatedValue] = useState<
    number | undefined
  >(undefined);
  const [maxAccumulatedValue, setMaxAccumulatedValue] = useState<
    number | undefined
  >(undefined);
  const [minValue, setMinValue] = useState<number | undefined>(undefined);
  const [maxValue, setMaxValue] = useState<number | undefined>(undefined);

  const currentPlayer = () => {
    if (!location) {
      throw new Error('No player selected');
    }
    const path = location.pathname.split('/');
    const playerFromPath: string | undefined =
      path.length >= 3 ? path[2] : undefined;
    return playerFromPath;
  };

  useEffect(() => {
    const retrieveStats = async () => {
      const playerId = currentPlayer();
      if (!playerId) {
        return;
      }
      const userData = await getUser(playerId);
      setStats(userData.stats as SinglePlayerStats);
      setGameIdPlayed(userData.games as [string]);
    };
    retrieveStats();
  }, []);

  useEffect(() => {
    if (stats) {
      accumulateChips();
    }
  }, [stats]);

  useEffect(() => {
    if (gameIdPlayed) {
      processLabels();
    }
  }, [gameIdPlayed]);

  const transformData = () => {
    return stats?.results.winsWithPoints.reduce(
      (prev: any, current: any, index: any) => {
        if (index < 5 || index === 10) {
          return [
            ...prev,
            {
              selfdraw: current.selfdraw,
              regular: current.regular,
              shooter: stats?.results.shooter[index],
            },
          ];
        } else {
          prev[5] = {
            selfdraw: (prev[5]?.selfdraw || 0) + current.selfdraw,
            regular: (prev[5]?.regular || 0) + current.regular,
            shooter: (prev[5]?.shooter || 0) + stats?.results.shooter[index],
          };
          return prev;
        }
      },
      []
    );
  };

  const accumulateChips = () => {
    const result: Array<number> = [];
    // for (let i = 0; i < 30; i++) {
    //   const sign = Math.random() > 0.2 ? 1 : -1;
    //   result.push(Math.random() * sign * 100);
    // }
    stats?.chipsByGame?.reduce((prev, current) => {
      const val = prev + current / 2;
      result.push(val);
      return val;
    }, 0);
    setMinAccumulatedValue(Math.min(...result));
    setMaxAccumulatedValue(Math.max(...result));
    setMinValue(Math.min(...(stats?.chipsByGame || [])) / 2);
    setMaxValue(Math.max(...(stats?.chipsByGame || [])) / 2);
    setAccumulatedChips(result);
  };

  const processLabels = () => {
    // const result: Array<number> = [];
    // for (let i = 0; i < 30; i++) {
    //   result.push(i);
    // }

    const result = gameIdPlayed?.map((val) => {
      const splitted = val.split('_');
      return `${splitted[1][0]}${splitted[1][1]}/${splitted[1][2]}${splitted[1][3]}`;
    });
    setLabels(result);
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      yAxes: {
        beginAtZero: true,
        grid: {
          display: true,
          lineWidth: (context: any) => (context.tick.value === 0 ? 2 : 0),
          color: '#5CE708',
        },
      },
      xAxes: {
        beginAtZero: true,
        grid: {
          display: true,
          lineWidth: (context: any) => (context.tick.value === 0 ? 2 : 0),
          color: '#5CE708',
        },
      },
    },
  };

  const legendRow = (left: ReactNode, right: ReactNode) => (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-around"
      mb={1}
    >
      <Box display="flex" alignContent="center">
        {left}
      </Box>
      <Box display="flex" alignContent="center">
        {right}
      </Box>
    </Box>
  );

  return (
    <WithLoader
      body={
        <Box
          height="100%"
          display="flex"
          justifyContent="space-evenly"
          flexDirection="column"
        >
          <Box>
            <Box display="flex" alignItems="center">
              <Emoji type={EmojiType.ABACUS} size={25} />
              <h1 style={{ margin: 0, marginLeft: 8 }}>
                {' '}
                Games played: {labels?.length}
              </h1>
            </Box>
            <span style={{ fontStyle: 'italic' }}>
              {stats?.duration &&
                `You've spent a total of ${parseMilliseconds(
                  stats.duration
                )} playing MJ!`}
            </span>
          </Box>

          <Box display="flex" alignItems="center">
            <Emoji type={EmojiType.MONEY} size={25} />
            <h1 style={{ margin: 0, marginLeft: 8 }}>
              {' '}
              Total: SGD {(stats?.results.chips || 0) / 2}
            </h1>
          </Box>
          <Box>
            <h1 style={{ margin: 0 }}>
              <Emoji type={EmojiType.HOURGLASS} size={25} /> Over time
            </h1>
            {legendRow(
              <>
                <Emoji
                  type={EmojiType.PLEADING_FACE}
                  size={20}
                  styles={{ marginRight: 4 }}
                />
                <>Biggest loss: {minValue}</>
              </>,
              <>
                <Emoji
                  type={EmojiType.MONEY_FACE}
                  size={20}
                  styles={{ marginRight: 4 }}
                />
                <>Biggest win: {maxValue}</>
              </>
            )}
            {legendRow(
              <>
                <Emoji
                  type={EmojiType.ARROW_DOWN_RIGHT}
                  size={20}
                  styles={{ marginRight: 4 }}
                />
                <>Min total: {minAccumulatedValue}</>
              </>,
              <>
                <Emoji
                  type={EmojiType.ARROW_UP_RIGHT}
                  size={20}
                  styles={{ marginRight: 4 }}
                />
                <>Max total: {maxAccumulatedValue}</>
              </>
            )}

            <Line
              type="line"
              data={{
                labels,
                datasets: [
                  {
                    data: accumulatedChips,
                    borderColor: 'rgb(159, 102, 231)',
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                  },
                ],
              }}
              options={options}
            />
          </Box>

          <TableContainer component={Paper}>
            <Table classes={{ root: classes.denseTable }} size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Points</TableCell>
                  <TableCell align="right">Wins (Selfdraw)</TableCell>
                  <TableCell align="right">Wins (Regular)</TableCell>
                  <TableCell align="right">Sharp Shooter</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transformData()?.map((data: any, index: any) => {
                  if (index === 0) return null;
                  return (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        <span style={{ whiteSpace: 'nowrap' }}>
                          {(index === 6 && 'Double max') ||
                            (index === 5 && 'Max') ||
                            index}
                        </span>
                      </TableCell>
                      <TableCell align="right">{data.selfdraw}</TableCell>
                      <TableCell align="right">{data.regular}</TableCell>
                      <TableCell align="right">{data.shooter}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      }
      footer={
        <Button size={12} mt={3} flexGrow={0} onClick={() => goBack()}>
          Go back
        </Button>
      }
      isLoading={!stats || !accumulatedChips || !labels}
    />
  );
  */
};
