import { useGame } from '../GameContext';
import { Player, points } from '../typings';
import Summary from '../common/Summary';
import { Emoji, EmojiType } from '../emojis';
import { Box } from 'reflexbox';

const WinSummary = ({
  effectivePoints,
  shooter,
}: {
  effectivePoints: number;
  shooter: Player | null;
}) => {
  const { currentPlayer } = useGame();
  const player = currentPlayer();

  const totalChips = shooter
    ? points[effectivePoints] * 4
    : points[effectivePoints] * 6;
  return (
    <Summary>
      <Box display="flex" alignItems="center" width="100%" p={2} mb={5}>
        <div style={{ textAlign: 'left', marginLeft: 10, marginRight: 10 }}>
          Yayyy {player.name} you get {totalChips}
        </div>
        <Emoji size={60} type={EmojiType.PARTY} />
      </Box>

      {shooter ? (
        <>
          <Box display="flex" alignItems="center" width="100%" p={2}>
            <div style={{ textAlign: 'left', marginLeft: 10, marginRight: 10 }}>
              Each players pays {points[effectivePoints]} to {player.name}
            </div>
            <Emoji size={60} type={EmojiType.MONEY} />
          </Box>

          <Box display="flex" alignItems="center" width="100%" p={2}>
            <div style={{ textAlign: 'left', marginLeft: 10, marginRight: 10 }}>
              {shooter.name} pays {points[effectivePoints] * 2}
            </div>
            <Emoji size={60} type={EmojiType.DEVIL} />
          </Box>
        </>
      ) : (
        <Box display="flex" alignItems="center" width="100%" p={2}>
          <div style={{ textAlign: 'left', marginLeft: 10, marginRight: 10 }}>
            Each players pays {points[effectivePoints] * 2} to {player.name}
          </div>
          <Emoji size={60} type={EmojiType.MONEY} />
        </Box>
      )}
    </Summary>
  );
};

export default WinSummary;
