import {
  createClient,
  PostgrestResponse,
  SupabaseClient,
} from '@supabase/supabase-js';

import { PostgrestFilterBuilder } from '@supabase/postgrest-js';
import { DBGame } from '../../supabase/databaseTypes';

let supabaseClient: SupabaseClient;
export const initSupabase = () => {
  if (supabaseClient) {
    return supabaseClient;
  }
  if (
    !process.env.REACT_APP_SUPABASE_URL ||
    !process.env.REACT_APP_SUPABASE_ANON_KEY
  ) {
    throw new Error('Supabase env var not defined');
  }
  supabaseClient = createClient(
    process.env.REACT_APP_SUPABASE_URL,
    process.env.REACT_APP_SUPABASE_ANON_KEY
  );
  return supabaseClient;
};

const throwOnFailure = async <T,>(
  callback: () => Promise<PostgrestResponse<T>>
): Promise<T> => {
  const result = await callback();

  if ((result.status >= 300 || result.status < 200) && result.error) {
    throw new Error(result.error.message);
  }
  return result?.data as unknown as T;
};

export const appendEvents = async (...events: any[]): Promise<DBGame> => {
  return throwOnFailure<DBGame>(async () =>
    supabaseClient.rpc('append_events', { events })
  );
};

export const undoEvents = async (groupingId: string): Promise<DBGame> => {
  return throwOnFailure<DBGame>(async () =>
    supabaseClient.rpc('undo_events', { grouping_id: groupingId })
  );
};

type ChipsByPlayer = [
  {
    chips: number;
    name: string;
  }
];
export const getChipsByPlayer = async (
  minDate: Date,
  groupId: string
): Promise<ChipsByPlayer> => {
  return throwOnFailure<ChipsByPlayer>(async () =>
    supabaseClient.rpc('total_chips', {
      min_date: minDate.toISOString(),
      group_id: groupId,
    })
  );
};

type RetriveByEqCondition = {
  name: string;
  value: any;
};

type RetrieveByCondition =
  | {
      builder: (
        builder: PostgrestFilterBuilder<any, any, any>
      ) => PostgrestFilterBuilder<any, any, any>;
    }
  | RetriveByEqCondition;

export const executeFunction = async <T,>(
  name: string,
  data: any
): Promise<T> => {
  return throwOnFailure(async () => {
    if (name === 'insertEntity') {
      return supabaseClient.from(data.entityName).insert(data.payload);
    }

    if (name === 'deleteEntityByFieldNameEqual') {
      return supabaseClient
        .from(data.entityName)
        .delete()
        .eq(data.fieldName, data.fieldValue);
    }

    if (name === 'updateEntityById') {
      return supabaseClient
        .from(data.entityName)
        .update(data.payload)
        .eq('id', data.payload.id);
    }

    if (name === 'retrieveBy') {
      let statement = supabaseClient.from(data.entityName).select();
      data.fields.forEach((f: RetrieveByCondition) => {
        if ('builder' in f) {
          statement = f.builder(statement);
        } else {
          statement = statement.eq(f.name, f.value);
        }
      });

      if (data.orderBy) {
        statement = statement.order(data.orderBy.column, data.orderBy.options);
      }

      if (data.limit) {
        statement = statement.limit(data.limit);
      }

      return statement;
    }
    throw new Error(`Not implemented: ${name}`);
  });
};
