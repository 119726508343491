export const parseMilliseconds = (ms: number) => {
  const secToMs = 1000;
  const minToMs = secToMs * 60;
  const hourToMs = minToMs * 60;
  const dayToMs = hourToMs * 24;

  var days = Math.floor(ms / dayToMs);
  var left = ms - days * dayToMs;
  var hours = Math.floor(left / hourToMs);
  left = left - hours * hourToMs;
  var minutes = Math.floor(left / minToMs);

  return `${days}d ${hours}h ${minutes}m`;
};

export const shuffleArray = (array: any[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
};
