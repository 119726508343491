import { Box } from 'reflexbox';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  MenuItem,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useGame } from '../GameContext';
import { Players, Seats, User } from '../typings';
import { Button, Select, useStyles } from '../common';
import { Emoji, EmojiType } from '../emojis';
import CheckSeating from './CheckSeating';
import { shuffleArray } from '../common/utils';
import { useAuth } from '../AuthContext';

const StartGame = () => {
  const history = useHistory();
  const { game } = useGame();
  const classes = useStyles();
  const { groupUsers } = useAuth().getAuthContext();

  const [fixedOrder, setFixedOrder] = useState<boolean>(false);
  const [selectedPlayers, setSelectedPlayers] = useState<
    Record<Seats, string | undefined>
  >({ 0: undefined, 1: undefined, 2: undefined, 3: undefined });
  const [temporaryPlayers, setTemporaryPlayers] = useState<Players>();

  useEffect(() => {
    if (history && game) {
      history.push('/players');
    }
  }, [history, game]);

  const addPlayer = (value: string, index: Seats) => {
    setSelectedPlayers({
      ...selectedPlayers,
      [index]: value,
    });
  };

  const submitPlayers = () => {
    if (
      !selectedPlayers ||
      !selectedPlayers[0] ||
      !selectedPlayers[1] ||
      !selectedPlayers[2] ||
      !selectedPlayers[3]
    ) {
      throw new Error();
    }

    const selectedPlayersArray = [
      selectedPlayers[0],
      selectedPlayers[1],
      selectedPlayers[2],
      selectedPlayers[3],
    ];
    if (!fixedOrder) {
      shuffleArray(selectedPlayersArray);
    }
    setTemporaryPlayers({
      0: selectedPlayersArray[0],
      1: selectedPlayersArray[1],
      2: selectedPlayersArray[2],
      3: selectedPlayersArray[3],
    });
  };

  const handleFixedOrderChange = (event: any, checked: boolean) => {
    setFixedOrder(checked);
  };

  const selectItem = (index: Seats) => {
    return (
      <Select
        label={`Player ${index + 1}`}
        value={selectedPlayers[index]}
        onChange={(event: { target: { value: string } }) =>
          addPlayer(event.target.value, index)
        }
      >
        {groupUsers.map((user: User) => (
          <MenuItem value={user.id}>
            <span className={classes.selectItem}>{user.name}</span>
          </MenuItem>
        ))}
      </Select>
    );
  };

  if (temporaryPlayers) {
    return (
      <CheckSeating
        players={temporaryPlayers}
        onReject={() => submitPlayers()}
      />
    );
  }

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      height="100vh"
      flexDirection="column"
      p={4}
    >
      <Box
        display="flex"
        justifyContent="space-evenly"
        alignItems="center"
        flexGrow={1}
        style={{ fontSize: 40 }}
      >
        <div
          style={{
            textShadow: `0 0 17px #96EF48, 0 0 70px rgba(150,239,72,0.5)`,
            fontWeight: 'bold',
          }}
        >
          Mahjongify
        </div>
        <Emoji type={EmojiType.MAHJONG} />
        <Emoji type={EmojiType.RABBIT} />
      </Box>

      <Box flexGrow={1}>
        <form noValidate autoComplete="off">
          <h2>Enter players name:</h2>
          {[0, 1, 2, 3].map((index) => selectItem(index as Seats))}
        </form>

        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={fixedOrder}
                onChange={handleFixedOrderChange}
              />
            }
            label="Is fixed order ?"
          />
        </FormGroup>
      </Box>

      <Button flexGrow={0} fullWidth onClick={() => submitPlayers()}>
        Start game
      </Button>
    </Box>
  );
};

export default StartGame;
