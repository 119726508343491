import { Box } from 'reflexbox';
import { v4 as uuidv4 } from 'uuid';
import { useEffect, useState } from 'react';
import { Button, Control } from '../common';
import { useGame } from '../GameContext';
import { ActionType, Event, Player, points } from '../typings';
import { Emoji, EmojiType } from '../emojis';
import { WinningCombination } from './Combination';
import AsyncScreenTransition from '../AsyncScreenTransition';

const Shooter = ({
  onSubmit,
  totalPoints,
  winningCombination,
}: {
  onSubmit: (effectivePoints: number, shooter: Player | null) => void;
  totalPoints: number;
  winningCombination: WinningCombination;
}) => {
  const { currentPlayer, appendEvents, players } = useGame();
  const player = currentPlayer();

  const [isSelfdraw, setIsSelfdraw] = useState<boolean>(false);
  const [shooter, setShooter] = useState<Player | null>(null);
  const [gains, setGains] = useState<number>(0);
  const effectivePoints = totalPoints > 10 ? 10 : totalPoints;
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);

  useEffect(() => {
    const gainsEarned = isSelfdraw
      ? points[effectivePoints] * 2 * 3
      : points[effectivePoints] * 2 + points[effectivePoints] * 2;
    setGains(gainsEarned);
  }, [effectivePoints, isSelfdraw]);

  const submit = () => {
    if (!isSelfdraw && !shooter) {
      return;
    }
    setHasSubmitted(true);
  };

  const saveEvents = async () => {
    const id = uuidv4();
    const events: Event[] = [
      {
        playerName: player.name,
        playerId: player.id,
        chips: gains,
        action: ActionType.WIN,
        metadata: {
          points: effectivePoints,
          selfdraw: isSelfdraw,
          hand: winningCombination,
        },
        id,
      },
    ];

    if (isSelfdraw) {
      players
        .filter((p) => p.name !== player.name)
        .map((p) =>
          events.push({
            playerName: p.name,
            playerId: p.id,
            chips: -points[effectivePoints] * 2,
            action: ActionType.LOST,
            metadata: {
              points: effectivePoints,
              selfdraw: true,
              shooter: false,
            },
            id,
          })
        );
    } else if (shooter) {
      events.push({
        playerName: shooter.name,
        playerId: shooter.id,
        chips: -points[effectivePoints] * 2,
        action: ActionType.LOST,
        metadata: {
          points: effectivePoints,
          selfdraw: false,
          shooter: true,
        },
        id,
      });

      players
        .filter((p) => p.name !== player.name && p.name !== shooter.name)
        .map((p) =>
          events.push({
            playerName: p.name,
            playerId: p.id,
            chips: -points[effectivePoints],
            action: ActionType.LOST,
            metadata: {
              points: effectivePoints,
              selfdraw: false,
              shooter: false,
            },
            id,
          })
        );
    }
    await appendEvents(...events);
    onSubmit(effectivePoints, shooter);
  };

  if (hasSubmitted) {
    return (
      <AsyncScreenTransition
        asyncCall={saveEvents}
        onForceContinue={async () => onSubmit(effectivePoints, shooter)}
      />
    );
  }

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      flexDirection="column"
      height="100vh"
    >
      <Box m={2}>
        <h1>Select the shooter or selfdraw</h1>
        <Box mx={-2} display="flex" flexDirection="column">
          {players
            .filter((p) => p.name !== player.name)
            .map((p) => (
              <Control
                key={p.name}
                onClick={() => {
                  setIsSelfdraw(false);
                  setShooter(players.find((pp) => pp.id === p.id) || null);
                }}
                checked={p.name === shooter?.name}
                m={2}
              >
                {p.name}
              </Control>
            ))}
          <Control
            onClick={() => {
              setIsSelfdraw(true);
              setShooter(null);
            }}
            checked={isSelfdraw}
            m={2}
          >
            Selfdraw
            <Box display="flex" alignItems="center" ml={2}>
              <Emoji size={40} type={EmojiType.COOL} />
              <Box
                display="flex"
                ml={1}
                alignItems="center"
                flexDirection="column"
              >
                <Emoji size={35} type={EmojiType.DIAMOND} />
                <Emoji
                  size={50}
                  styles={{ marginTop: '-30px', zIndex: -1 }}
                  type={EmojiType.HANDS}
                />
              </Box>
            </Box>
          </Control>
        </Box>

        <Box
          p={2}
          pt={4}
          pb={4}
          display="flex"
          justifyContent="space-between"
          fontSize="18px"
        >
          <div>Total points: {effectivePoints}</div>
          <div>Total gains: {gains}</div>
        </Box>
      </Box>

      <Button m={2} size={12} flexGrow={0} onClick={() => submit()}>
        Submit
      </Button>
    </Box>
  );
};

export default Shooter;
