import { Box } from '@material-ui/core';
import { ReactNode } from 'react';

export const Layout = ({
  body,
  footer,
}: {
  body: ReactNode;
  footer: ReactNode;
}) => {
  return (
    <Box
      display="flex"
      height="100vh"
      mx={3}
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box
        display="flex"
        height="100%"
        flexDirection="column"
        justifyContent="space-evenly"
      >
        {body}
      </Box>

      <Box>{footer}</Box>
    </Box>
  );
};
