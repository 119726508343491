import { Box } from 'reflexbox';
import { Button, Layout } from './common';
import { ReactNode, useEffect } from 'react';
import { useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

type Props =
  | {
      asyncCall: () => Promise<unknown>;
      children: ReactNode;
      onForceContinue?: undefined;
    }
  | {
      asyncCall: () => Promise<unknown>;
      onForceContinue: () => Promise<unknown>;
      children?: undefined;
    };

const AsyncScreenTransition = (props: Props) => {
  const { asyncCall, onForceContinue, children = null } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  const saveEvents = async () => {
    setIsLoading(true);
    try {
      // await new Promise((resolve) =>
      //   setTimeout(() => {
      //     resolve(null);
      //   }, 3000)
      // );
      await asyncCall();
    } catch (error) {
      console.log(error);
      setErrorMessage((error as Error)?.message);
      setHasError(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    saveEvents();
  }, []);

  if (isLoading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  const onContinueWithoutSaving = async () => {
    setHasError(false);
    onForceContinue && (await onForceContinue());
  };

  if (hasError) {
    return (
      <Layout
        body={
          <Alert severity="error">
            An error has happened while saving the game state:
            <br />
            <br />
            {errorMessage}
          </Alert>
        }
        footer={
          <>
            <Button my={2} onClick={() => saveEvents()}>
              Retry
            </Button>
            <Button my={2} onClick={onContinueWithoutSaving}>
              Continue without saving
            </Button>
          </>
        }
      />
    );
  }
  return <>{children}</>;
};

export default AsyncScreenTransition;
