import { Box as MuiBox } from '@material-ui/core';

export const CenteredContentBox = (props: any) => {
  const { children, size, ...boxProps } = props;
  return (
    <MuiBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      {...boxProps}
    >
      {children}
    </MuiBox>
  );
};
