const { makeStyles } = require('@material-ui/core');

export const useStyles = makeStyles({
  root: {
    marginTop: -20,
    padding: 20,
  },

  text: {
    fontFamily: 'Montserrat,helvetica,arial,sans-serif',
    fontSize: 48,
    fontWeight: 500,
    textAlign: 'center',
  },

  textLeft: {
    fontFamily: 'Montserrat,helvetica,arial,sans-serif',
    fontSize: 30,
    textAlign: 'center',
  },

  accordion: {
    marginTop: 20,
    minHeight: 30,
    maxHeight: 30,
  },

  denseTable: {
    '& .MuiTableCell-sizeSmall': {
      padding: '6px 10px 6px 10px',
    },
  },

  neon: {
    color: '#FFFFFF',
    fontFamily: 'Noto Sans SC',
    fontWeight: 400,
    textShadow: `0 0 17px #96EF48, 0 0 70px rgba(150,239,72,0.5)`,
  },

  negativeButton: {
    borderColor: '#E26565 !important',
  },
});
