import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useGame } from '../../GameContext';
import { ActionType, Player } from '../../typings';
import { Button } from '../../common';
import { Box } from 'reflexbox';
import Summary from '../../common/Summary';
import { Emoji, EmojiType } from '../../emojis';
import AsyncScreenTransition from '../../AsyncScreenTransition';

const PayFromSinglePlayer = ({
  chips,
  actionType,
  metadata,
}: {
  chips: number;
  actionType: ActionType;
  metadata?: Record<string, string>;
}) => {
  const [payingPlayer, setPayingPlayer] = useState<Player | null>(null);
  const { players, currentPlayer, appendEvents } = useGame();
  const player = currentPlayer();

  const saveEvents = async (payingPlayer: Player) => {
    const id = uuidv4();
    await appendEvents(
      {
        playerName: player.name,
        playerId: player.id,
        chips: chips,
        action: actionType,
        metadata: metadata || null,
        id,
      },
      {
        playerName: payingPlayer.name,
        playerId: payingPlayer.id,
        chips: -chips,
        action: actionType,
        metadata: metadata || null,
        id,
      }
    );
  };

  if (payingPlayer) {
    return (
      <AsyncScreenTransition asyncCall={() => saveEvents(payingPlayer)}>
        <Summary>
          <Emoji type={EmojiType.ANGBAO} size={90} />
          <br />
          {payingPlayer.name} pays {chips} chips to {player.name} for{' '}
          {actionType.toString().toLowerCase()}
        </Summary>
      </AsyncScreenTransition>
    );
  }

  return (
    <Box
      display="flex"
      justifyContent="space-evenly"
      flexDirection="column"
      height="100vh"
    >
      <Box m={2}>
        <h1>Paid from:</h1>
      </Box>

      {players
        .filter((p) => p.name !== player.name)
        .map((p) => (
          <Button
            m={2}
            my={4}
            key={p.name}
            onClick={() =>
              setPayingPlayer(players.filter((e) => e.name === p.name)[0])
            }
          >
            {p.name}
          </Button>
        ))}
    </Box>
  );
};

export default PayFromSinglePlayer;
