import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { Player, Event, Game, Seats } from './typings';
import {
  executeFunction,
  appendEvents as rpcAppendEvents,
  undoEvents as rpcUndoEvents,
} from './common/supabase';
import { useAuth } from './AuthContext';
import { WithCircularLoader } from './common/WithCircularLoader';
import { DBGame } from '../supabase/databaseTypes';

type GameContextType = {
  players: Player[];
  currentPlayer: () => Player;
  startGame: (game: Game) => Promise<void>;
  game: DBGame;
  appendEvents: (...events: Event[]) => Promise<void>;
  undoEvents: (groupingId: string) => Promise<void>;
  endGame: () => void;
  backHome: () => void;
  currentPlayerTurn: () => Player;
  hasGameEnded: () => boolean;
};

const GameContext = React.createContext<GameContextType | undefined>(undefined);

export const useGame = () => {
  const context = React.useContext(GameContext);
  if (!context) {
    throw new Error('Undefined context');
  }
  return context;
};

export const GameContextProvider = ({
  children,
}: {
  children: React.ReactElement[];
}) => {
  const history = useHistory();
  const location = useLocation();

  const { group, groupUsers } = useAuth().getAuthContext();

  const [players, setPlayers] = useState<Player[]>([]);
  const [game, setGame] = useState<DBGame | undefined>(undefined);
  const [isCreatingGame, setIsCreatingGame] = useState<boolean>(false);

  const withNullableGame =
    ['/menu', '/start'].indexOf(history.location.pathname) > -1;

  const startGame = async (game: Game) => {
    setIsCreatingGame(true);
    await executeFunction<void>('insertEntity', {
      entityName: 'games',
      payload: game,
    });
    setGame(game);
  };

  useEffect(() => {
    if (game && players.length === 4 && isCreatingGame) {
      setIsCreatingGame(false);
      history.push('/players');
    }
  }, [game, players, isCreatingGame]);

  useEffect(() => {
    if (game || !group) {
      return;
    }
    const loadOnGoingGame = async () => {
      const onGoingGames = await executeFunction<DBGame[]>('retrieveBy', {
        entityName: 'games',
        fields: [
          { name: 'group_id', value: group.id },
          { name: 'is_game_over', value: false },
        ],
      });

      // TODO: be able to chose between games
      if (onGoingGames.length) {
        setGame(onGoingGames[0]);
      } else if (!withNullableGame) {
        history.push('/menu');
      }
    };

    loadOnGoingGame();
  }, [game, group]);

  useEffect(() => {
    if (
      !game ||
      !game.players ||
      players.length === 4 ||
      !groupUsers ||
      groupUsers.length < 4
    ) {
      return;
    }

    const playersToSet: Player[] = [];
    [0, 1, 2, 3].forEach((index) => {
      const seat = index as Seats;
      playersToSet.push({
        id: game!.players![seat],
        name:
          groupUsers?.find((u) => u.id === game!.players![seat])?.name ||
          'Unknown',
      });
    });
    setPlayers(playersToSet);
  }, [game, groupUsers]);

  const currentPlayer = () => {
    if (!location) {
      throw new Error('No player selected');
    }
    const path = location.pathname.split('/');
    const playerFromPath: string | undefined =
      path.length >= 3 ? path[2] : undefined;
    if (playerFromPath === undefined) {
      throw new Error('No player selected');
    }
    const playerToReturn = players.find((p) => p.name === playerFromPath);
    if (playerToReturn === undefined) {
      throw new Error('No player selected');
    }
    return playerToReturn;
  };

  const appendEvents = async (...events: Event[]) => {
    if (!game) {
      return;
    }
    const date = new Date();

    const updatedGame = await rpcAppendEvents(
      events.map((e) => ({
        id: uuidv4(),
        created_at: date,
        grouping_id: e.id,
        game_id: game?.id,
        action_type: e.action,
        action_data: e.metadata,
        chips: e.chips,
        player_id: e.playerId,
      }))
    );
    setGame(updatedGame);
  };

  const undoEvents = async (groupingId: string) => {
    const updatedGame = await rpcUndoEvents(groupingId);
    setGame(updatedGame);
  };

  const endGame = () => {
    setGame(undefined);
    setPlayers([]);
    history.push('/');
  };

  const backHome = () => {
    history.push('/players');
  };

  const currentPlayerTurn = () => {
    if (!game || !players) {
      throw new Error("Game hasn't started");
    }
    return players[game.turn];
  };

  const hasGameEnded = () => (game && game.is_game_over ? true : false);

  const value = {
    players,
    currentPlayer,
    startGame,
    game: game as Game,
    appendEvents,
    undoEvents,
    backHome,
    endGame,
    currentPlayerTurn,
    hasGameEnded,
  };

  return (
    <GameContext.Provider value={value}>
      <WithCircularLoader
        isLoading={(!game || players.length !== 4) && !withNullableGame}
      >
        {children}
      </WithCircularLoader>
    </GameContext.Provider>
  );
};
