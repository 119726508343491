import { useState } from 'react';
import { Player } from '../typings';
import Combination, { WinningCombination } from './Combination';
import Shooter from './Shooter';
import WinSummary from './WinSummary';

export type pointsMap = {
  [name: string]: { points: number; isRare: boolean; displayName: string };
};

export type cumulablePointsMap = {
  [name: string]: {
    points: number;
    isRare: boolean;
    apply: (num: number) => number;
    displayName: string;
  };
};

enum Step {
  FIXED,
  SHOOTER,
  SUMMARY,
}

const steps = [Step.FIXED, Step.SHOOTER, Step.SUMMARY];

const Win = () => {
  const [step, setStep] = useState<Step>(Step.FIXED);
  const [totalPoints, setTotalPoints] = useState<number>(0);
  const [shooter, setShooter] = useState<Player | null>(null);
  const [effectivePoints, setEffectivePoints] = useState<number>(0);
  const [winningCombination, setWinningCombination] =
    useState<WinningCombination | null>(null);

  const goToNextStep = () => {
    const stepIndex = steps.indexOf(step);
    setStep(stepIndex + 1);
  };

  const addToTotalPoints = (
    pointsToAdd: number,
    winningCombination: WinningCombination
  ) => {
    setWinningCombination(winningCombination);
    const initialPoints = totalPoints;
    setTotalPoints(initialPoints + pointsToAdd);
    goToNextStep();
  };

  if (step === Step.FIXED) {
    return <Combination onSubmit={addToTotalPoints} />;
  }

  if (step === Step.SHOOTER) {
    if (winningCombination == null) {
      throw new Error('Winning hand should be defined');
    }
    return (
      <Shooter
        totalPoints={totalPoints}
        winningCombination={winningCombination}
        onSubmit={(effectivePoints: number, shooter: Player | null) => {
          setShooter(shooter);
          setEffectivePoints(effectivePoints);
          goToNextStep();
        }}
      />
    );
  }

  if (step === Step.SUMMARY) {
    return <WinSummary effectivePoints={effectivePoints} shooter={shooter} />;
  } else return null;
};

export default Win;
