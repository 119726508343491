import { v4 as uuidv4 } from 'uuid';
import { useGame } from '../../GameContext';
import Summary from '../../common/Summary';
import { Emoji, EmojiType } from '../../emojis';
import { ActionType } from '../../typings';
import AsyncScreenTransition from '../../AsyncScreenTransition';

const PayToAllOtherPlayers = ({
  chips,
  actionType,
  metadata,
}: {
  chips: number;
  actionType: ActionType;
  metadata?: Record<string, string>;
}) => {
  const { currentPlayer, players, appendEvents } = useGame();
  const player = currentPlayer();

  const saveEvents = async () => {
    const id = uuidv4();
    const events = [
      {
        playerName: player.name,
        playerId: player.id,
        chips: -chips * 3,
        action: actionType,
        metadata: metadata || null,
        id,
      },
    ];

    players
      .filter((p) => p.name !== player.name)
      .map((p) =>
        events.push({
          playerName: p.name,
          playerId: p.id,
          chips: chips,
          action: actionType,
          metadata: metadata || null,
          id,
        })
      );

    await appendEvents(...events);
  };

  return (
    <AsyncScreenTransition asyncCall={saveEvents}>
      <Summary>
        <Emoji size={90} type={EmojiType.DEVIL} /> <br />
        OMG {player.name} you pay {chips} to each player
      </Summary>
    </AsyncScreenTransition>
  );
};

export default PayToAllOtherPlayers;
