import { v4 as uuidv4 } from 'uuid';
import { useGame } from '../../GameContext';
import { NoPaymentAction } from '../../typings';
import Summary from '../../common/Summary';
import AsyncScreenTransition from '../../AsyncScreenTransition';

const NoPayment = ({ action }: { action: NoPaymentAction }) => {
  const { currentPlayer, appendEvents } = useGame();
  const player = currentPlayer();

  const saveEvents = async () => {
    const id = uuidv4();
    await appendEvents({
      playerName: player.name,
      playerId: player.id,
      chips: 0,
      action: action.type,
      id,
      metadata: null,
    });
  };

  return (
    <AsyncScreenTransition asyncCall={saveEvents}>
      <Summary>{action.message}</Summary>
    </AsyncScreenTransition>
  );
};

export default NoPayment;
