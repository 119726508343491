import { Box } from 'reflexbox';
import { useGame } from '../GameContext';
import { makeStyles, TextField } from '@material-ui/core';
import { Button, Layout, useStyles } from '../common';
import { useState } from 'react';

import { v4 as uuidv4 } from 'uuid';
import { ActionType } from '../typings';
import AsyncScreenTransition from '../AsyncScreenTransition';

const useLocalStyles = makeStyles({
  players: {
    color: 'white',
    fontSize: 26,
  },
});

const Reconcile = ({ onClose }: { onClose: () => void }) => {
  const styles = { ...useStyles(), ...useLocalStyles() };
  const { players, appendEvents } = useGame();
  const [chips, setChips] = useState<{ count: number; playerId: string }[]>([]);
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);

  const saveEvents = async () => {
    const id = uuidv4();
    const events = chips.map((c) => {
      const playerName = players.find((p) => p.id === c.playerId)?.name;
      if (!playerName) {
        throw new Error();
      }
      return {
        playerName,
        playerId: c.playerId,
        chips: c.count,
        action: ActionType.RECONCILE,
        id,
        metadata: null,
      };
    });
    await appendEvents(...events);
    onClose();
  };

  if (hasSubmitted) {
    return (
      <AsyncScreenTransition
        asyncCall={saveEvents}
        onForceContinue={async () => onClose()}
      />
    );
  }

  return (
    <Layout
      body={
        <Box
          display="flex"
          justifyContent="space-around"
          flexDirection="column"
          height="100%"
        >
          {players.map((p, index) => (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              className={styles.players}
            >
              {p.name}:
              <TextField
                id="filled-number"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="filled"
                onChange={(event) => {
                  const copy = [...chips];
                  copy[index] = {
                    count: +event.target.value,
                    playerId: p.id,
                  };
                  setChips(copy);
                }}
              />
            </Box>
          ))}
        </Box>
      }
      footer={
        <>
          <Button className={styles.negativeButton} my={2} onClick={onClose}>
            Cancel
          </Button>
          <Button
            my={2}
            onClick={() => setHasSubmitted(true)}
            disabled={
              chips.length !== 4 ||
              chips.reduce(
                (prev, current) => prev + (current ? current.count : 10000),
                0
              ) !== 0
            }
          >
            Submit
          </Button>
        </>
      }
    />
  );
};

export default Reconcile;
