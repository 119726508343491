import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Box } from 'reflexbox';
import AsyncScreenTransition from './AsyncScreenTransition';
import { Button, useStyles } from './common';
import { executeFunction } from './common/supabase';
import { DBGameEvent } from '../supabase/databaseTypes';
import { useGame } from './GameContext';
import { ActionType, Event } from './typings';

const Undo = () => {
  const { backHome, game, players, undoEvents } = useGame();
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [eventsToDelete, setEventsToDelete] = useState<
    Array<Event & { groupingId: string }> | undefined
  >(undefined);
  const classes = useStyles();

  if (!game) {
    throw new Error();
  }

  useEffect(() => {
    const apiCall = async () => {
      const events = await executeFunction<DBGameEvent[]>('retrieveBy', {
        entityName: 'game_events',
        fields: [
          {
            name: 'game_id',
            value: game.id,
          },
        ],
        orderBy: {
          column: 'created_at',
          options: { ascending: false },
        },
        limit: 5,
      });
      const domainEvents = events.map((e) => ({
        playerName:
          players.find((p) => p.id === e.player_id)?.name || e.player_id,
        playerId: e.player_id,
        chips: e.chips,
        action: e.action_type as ActionType,
        metadata: e.action_data as Event['metadata'],
        id: e.id,
        groupingId: e.grouping_id,
      }));

      setEventsToDelete(
        domainEvents.filter((e) => e.groupingId === domainEvents[0].groupingId)
      );
    };
    apiCall();
  }, []);

  if (isSuccessful) {
    return (
      <Box
        className={classes.textLeft}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        onClick={backHome}
      >
        Successfully undid previous action
      </Box>
    );
  }

  if (!isSuccessful && (!eventsToDelete || eventsToDelete?.length === 0)) {
    return (
      <Box
        className={classes.textLeft}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        onClick={backHome}
      >
        No events to undo
      </Box>
    );
  }

  const undoLastAction = async () => {
    if (!eventsToDelete) {
      return;
    }
    await undoEvents(eventsToDelete[0].groupingId);
    setIsSuccessful(true);
    setHasSubmitted(false);
  };

  if (hasSubmitted) {
    return (
      <AsyncScreenTransition
        asyncCall={undoLastAction}
        onForceContinue={async () => {
          setIsSuccessful(true);
          setHasSubmitted(false);
        }}
      />
    );
  }

  return (
    <Box mx={3} my={4}>
      <h1>Are you sure you want to undo these events?</h1>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="right">Player</TableCell>
              <TableCell align="right">Chips</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {eventsToDelete?.map((e) => (
              <TableRow key={`${e?.id}}-${e?.playerName}`}>
                <TableCell align="right">{e?.playerName}</TableCell>
                <TableCell align="right">{e?.chips}</TableCell>
                <TableCell align="right">{e?.action}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Button my={2} onClick={() => setHasSubmitted(true)}>
        Yes
      </Button>
      <Button className={classes.negativeButton} my={2} onClick={backHome}>
        No
      </Button>
    </Box>
  );
};

export default Undo;
