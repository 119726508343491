import { Box } from 'reflexbox';
import { useParams } from 'react-router';
import { Button, useStyles } from './common';
import { useEffect } from 'react';
import { useState } from 'react';
import { Group } from './typings';
import { Emoji, EmojiType } from './emojis';

export default () => {
  return null;
  /*
  const { groupId }: { groupId: string } = useParams();
  const classes = useStyles();

  const [group, setGroup] = useState<Group | undefined>(undefined);
  const [isAddedSuccesfully, setIsAddedSuccesfully] = useState<boolean>(false);

  useEffect(() => {
    const fetchGroup = async () => {
      const group = await getGroup(groupId);
      setGroup(group);
    };
    fetchGroup();
  }, []);

  const onClick = () => {
    signInWithPopup(getAuth(), new GoogleAuthProvider())
      .then((result) => {
        setIsAddedSuccesfully(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (!group) {
    return null;
  }

  if (isAddedSuccesfully) {
    return (
      <Box
        display="flex"
        className={classes.textLeft}
        justifyContent="space-evenly"
        height="100vh"
        flexDirection="column"
        p={4}
      >
        You've been succesfully added to the group, you can now leave the page{' '}
        <br />
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      className={classes.textLeft}
      justifyContent="space-evenly"
      height="100vh"
      flexDirection="column"
      p={4}
    >
      You've been invited to join <br />
      <Box display="flex">
        <Emoji type={EmojiType.ANGBAO} size={90} /> {group?.name}{' '}
        <Emoji type={EmojiType.ANGBAO} size={90} />
      </Box>
      <Button flexGrow={0} fullWidth onClick={onClick}>
        Click to join the group
      </Button>
    </Box>
  );
  */
};
